<template>
    <form
        class="crypto-limit-form"
        :class="s.mainContainer"
        @submit.prevent="performOrder"
    >
        <div :class="[s.row, s.w100, s.h100, s.pbM]">
            <div :class="[s.column, s.w66]">
                <div
                    v-if="isMarginAvailable"
                    :class="s.leverageContainer"
                >
                    <SelectLeverage
                        :leverages="availableLeverages"
                        :selected-leverage="selectedLeverage || 1"
                        @leverage="selectedLeverage = $event"
                        :is-kyc-verified="isKycVerified"
                        :disabled="!isMarginEnabled"
                    />
                </div>
                <div :class="[s.row, s.w100]">
                    <div :class="[s.column, s.w50, s.rowGap, s.firstColumnPadding]">
                        <money-input
                            title="Quantity"
                            ref="QuantityInput"
                            placeholder="0"
                            has-arrows
                            :currency="baseAssetSymbol"
                            :buttons="quantityButtons"
                            :is-buttons-disabled="isQuantityButtonsDisabled"
                            :step="1 / 10 ** quantityPrecision"
                            :restrictions="quantityRestriction"
                            :max-precision="quantityPrecision"
                            :value="quantity"
                            :disabled-buttons-indexes="disabledQuantityButtonsIndexes"
                            :show-tooltip="showTooltip"
                            :available="quantityBalanceFree"
                            :is-external-error="$v.quantity.$error"
                            :external-error-text="quantityErrorText"
                            @input="setQuantity"
                        />
                        <money-input
                            title="Total"
                            ref="TotalInput"
                            placeholder="0"
                            type="number"
                            has-arrows
                            :buttons="totalButtons"
                            :currency="quoteAssetSymbol"
                            :step="1 / 10 ** pricePrecision"
                            :restrictions="totalRestriction"
                            :max-precision="pricePrecision"
                            :is-buttons-disabled="isTotalButtonsDisabled"
                            :value="total"
                            :disabled-buttons-indexes="disabledTotalButtonsIndexes"
                            :show-tooltip="showTooltip"
                            :available="totalBalanceFree"
                            :is-external-error="$v.total.$error"
                            :external-error-text="totalErrorText"
                            @input="setTotal"
                        />
                    </div>
                    <div :class="[s.column, s.w50, s.secondColumnPadding]">
                        <money-input
                            title="Price"
                            ref="PriceInput"
                            placeholder="0"
                            has-arrows
                            :currency="quoteAssetSymbol"
                            :buttons="priceButtons"
                            :step="1 / 10 ** pricePrecision"
                            :restrictions="priceRestriction"
                            :max-precision="pricePrecision"
                            :is-always-active="true"
                            :value="price"
                            :show-tooltip="showTooltip"
                            :is-external-error="$v.price.$error"
                            :external-error-text="priceErrorText"
                            @input="setPrice"
                        />
                    </div>
                </div>
            </div>
            <div :class="[s.column, s.w33, s.justifyContentBetween, s.prS]">
                <OrderValueBlock
                    :exchange="currentPlacement ? currentPlacement.name : ''"
                    :pair="baseAssetSymbol && quoteAssetSymbol ? `${baseAssetSymbol}/${quoteAssetSymbol}` : ''"
                    :placement="placement"
                    :fee="newCommission && newCommission > 0 ? newCommission : 0"
                    :quoted-fee="quotedCommission && quotedCommission > 0 ? quotedCommission : 0"
                    :side="activeSide.value"
                    :order-value="orderValue"
                    :fee-asset="commissionAsset"
                    :fees-rate="storeData.feesRate"
                    :quantity-precision="quantityPrecision"
                    order-type="limit"
                />
                <div v-if="!isModal" :class="[s.row, s.w100]">
                    <Button
                        @click="openMultiaccountOrderModal"
                        :class="s.multiaccountButton"
                        :button-disabled="!isKycVerified || isMarginEnabled"
                        button-type="primary"
                    >
                        <template>
                            <Icon icon="multiaccount_order" />
                        </template>
                    </Button>
                    <Button
                        button-type="primary"
                        :button-disabled="!isKycVerified"
                        is-submit
                        wide
                        @mousedown="toggleDemoAccountAlert"
                    >
                        <template>
                            {{ `${$store.getters.localization.Body.Terminal.Trading.OrderSides[activeSide.field]} ${baseAssetSymbol} limit` }}
                        </template>
                    </Button>
                </div>
            </div>
        </div>
        <div v-if="isModal" :class="[s.row, s.w100, s.modalContainer]">
            <div :class="[s.w100, s.mtMedium]">
                <div
                    v-for="(account, index) in accountsList"
                    :key="account.name"
                >
                    <span
                        v-if="index === 0"
                        :class="s.accountsListHeaderText"
                    >
                        Owned Accounts
                    </span>
                    <span
                        v-if="index === firstManagedAccountIndex"
                        :class="s.accountsListHeaderText"
                    >
                        Managed Accounts
                    </span>
                    <div :class="[s.accountsListContainer, s.mainAccountsGrid]">
                        <div>
                            <div :class="[s.row, s.alignCenter, s.smallGap]">
                                <Checkbox
                                    :value="multiaccountOrderFormData.selectedAccountsIndexes[index]"
                                    @state-changed="handleAccountCheckbox(index)"
                                />
                                <span
                                    :class="s.tag"
                                >
                                    <AccountColorMarker
                                        :color="getAccountColor(account)"
                                    />
                                    #{{ account.name }}
                                </span>
                            </div>
                        </div>
                        <div>
                            <div :class="s.balancesGrid">
                                <div :class="s.mlSmall">
                                    <p :class="theme.placeorder.multiallountSubheaderText">Total Balance</p>
                                    <AssetValue
                                        :value="((account.totalQuotations[quotationAssetSymbol] - account.holdQuotations[quotationAssetSymbol]) || 0).floor(quotationAssetPrecision)"
                                        :asset="quotationAssetSymbol"
                                        :need-to-round="false"
                                        :value-class="theme.placeorder.multiaccountValueText"
                                    />
                                </div>
                                <div :class="s.mlSmall">
                                    <p :class="theme.placeorder.multiallountSubheaderText">
                                        Available {{ baseAssetSymbol }}
                                    </p>
                                    <AssetValue
                                        :value="Number(accountFreeBase(account.id).floor(8)).noExponents()"
                                        :asset="baseAssetSymbol"
                                        :need-to-round="false"
                                        :value-class="theme.placeorder.multiaccountValueText"
                                    />
                                </div>
                                <div :class="s.mlSmall">
                                    <p :class="theme.placeorder.multiallountSubheaderText">
                                        Available {{ quoteAssetSymbol }}
                                    </p>
                                    <AssetValue
                                        :value="Number(accountFreeQuote(account.id).floor(8)).noExponents()"
                                        :asset="quoteAssetSymbol"
                                        :need-to-round="false"
                                        :value-class="theme.placeorder.multiaccountValueText"
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div :class="[s.row, s.toggleBarsContainer]">
                                <SwitchControl
                                    :value="multiaccountOrderFormData.selectedAccountsSumTypes[index]"
                                    :disabled="!multiaccountOrderFormData.selectedAccountsIndexes[index]"
                                    :class="s.prXxxl"
                                    @change="changeSwitchValue(index)"
                                    with-text="Σ"
                                    off-text="%"
                                />
                                <Checkbox
                                    :value="multiaccountOrderFormData.selectedAccountsIsEditing[index]"
                                    @state-changed="handleEditCheckbox(index)"
                                    :disabled="!multiaccountOrderFormData.selectedAccountsIndexes[index]"
                                    :label-class="s.editText"
                                >
                                    <template>
                                        Edit
                                    </template>
                                </Checkbox>
                            </div>
                        </div>
                        <div
                            v-if="multiaccountOrderFormData.selectedAccountsIndexes[index]"
                            :class="s.additionalGridCell"
                        >
                            <div
                                v-if="!multiaccountOrderFormData.selectedAccountsIsEditing[index]"
                                :class="[s.row, s.w100, s.justifyContentBetween]"
                            >
                                <div :class="[s.column, s.w33, s.paddingX, s.paddingY, s.rowGap]">
                                    <money-input
                                        title="Quantity"
                                        placeholder="0"
                                        :currency="baseAssetSymbol"
                                        :step="1 / 10 ** quantityPrecision"
                                        :restrictions="quantityRestriction"
                                        :max-precision="quantityPrecision"
                                        :value="multiaccountOrderFormData.quantities[index]"
                                        :show-tooltip="showTooltip"
                                        :is-external-error="multiaccountOrderFormData.quantityValidationErrors[index]"
                                        :external-error-text="multiaccountOrderFormData.quantityValidationErrorTexts[index]"
                                        disabled
                                        readonly
                                    />
                                    <money-input
                                        title="Total"
                                        placeholder="0"
                                        type="number"
                                        :currency="quoteAssetSymbol"
                                        :step="1 / 10 ** pricePrecision"
                                        :restrictions="totalRestriction"
                                        :max-precision="pricePrecision"
                                        :value="multiaccountOrderFormData.totals[index]"
                                        :show-tooltip="showTooltip"
                                        :is-external-error="multiaccountOrderFormData.totalValidationErrors[index]"
                                        :external-error-text="multiaccountOrderFormData.totalValidationErrorTexts[index]"
                                        disabled
                                        readonly
                                    />
                                </div>
                                <div :class="[s.column, s.w33, s.paddingX, s.paddingY, s.borderRight]">
                                    <money-input
                                        title="Price"
                                        placeholder="0"
                                        :currency="quoteAssetSymbol"
                                        :step="1 / 10 ** pricePrecision"
                                        :restrictions="priceRestriction"
                                        :max-precision="pricePrecision"
                                        :value="multiaccountOrderFormData.prices[index]"
                                        disabled
                                        readonly
                                    />
                                </div>
                                <div :class="[s.column, s.w33, s.paddingX]">
                                    <OrderValueBlock
                                        :exchange="currentPlacement ? currentPlacement.name : ''"
                                        :placement="placement"
                                        :pair="baseAssetSymbol && quoteAssetSymbol ? `${baseAssetSymbol}/${quoteAssetSymbol}` : ''"
                                        :fee="multiaccountOrderFormData.fees[index]"
                                        :quoted-fee="multiaccountOrderFormData.quotedFees[index]"
                                        :side="activeSide.value"
                                        :order-value="multiaccountOrderFormData.orderValues[index]"
                                        :fee-asset="commissionAsset"
                                        :fees-rate="storeData.feesRate"
                                        :quantity-precision="quantityPrecision"
                                        order-type="limit"
                                        :need-background="false"
                                    />
                                </div>
                            </div>
                            <div v-else :class="[s.row, s.w100, s.justifyContentBetween]">
                                <div :class="[s.column, s.w33, s.paddingX, s.rowGap]">
                                    <money-input
                                        title="Quantity"
                                        placeholder="0"
                                        has-arrows
                                        :currency="baseAssetSymbol"
                                        :step="1 / 10 ** quantityPrecision"
                                        :restrictions="quantityRestriction"
                                        :max-precision="quantityPrecision"
                                        :value="multiaccountOrderFormData.quantities[index]"
                                        :buttons="makeMultiaccountQuantityButtons(index)"
                                        :is-buttons-disabled="isMultiaccountQuantityButtonsDisabled(index)"
                                        :disabled-buttons-indexes="getMultiaccountDisabledQuantityButtonsIndexes(index)"
                                        :show-tooltip="showTooltip"
                                        :is-external-error="multiaccountOrderFormData.quantityValidationErrors[index]"
                                        :external-error-text="multiaccountOrderFormData.quantityValidationErrorTexts[index]"
                                        @input="setMultiaccountQuantity($event, index)"
                                    />
                                    <money-input
                                        title="Total"
                                        placeholder="0"
                                        type="number"
                                        has-arrows
                                        :currency="quoteAssetSymbol"
                                        :step="1 / 10 ** pricePrecision"
                                        :restrictions="totalRestriction"
                                        :max-precision="pricePrecision"
                                        :value="multiaccountOrderFormData.totals[index]"
                                        :buttons="makeMultiaccountTotalButtons(index)"
                                        :is-buttons-disabled="isMultiaccountTotalButtonsDisabled(index)"
                                        :disabled-buttons-indexes="getMultiaccountDisabledTotalButtonsIndexes(index)"
                                        :show-tooltip="showTooltip"
                                        :is-external-error="multiaccountOrderFormData.totalValidationErrors[index]"
                                        :external-error-text="multiaccountOrderFormData.totalValidationErrorTexts[index]"
                                        @input="setMultiaccountTotal($event, index)"
                                    />
                                </div>
                                <div :class="[s.column, s.w33, s.paddingX, s.borderRight]">
                                    <money-input
                                        title="Price"
                                        placeholder="0"
                                        has-arrows
                                        :currency="quoteAssetSymbol"
                                        :step="1 / 10 ** pricePrecision"
                                        :restrictions="priceRestriction"
                                        :max-precision="pricePrecision"
                                        :is-always-active="true"
                                        :value="multiaccountOrderFormData.prices[index]"
                                        :buttons="makeMultiaccountPriceButtons(index)"
                                        @input="setMultiaccountPrice($event, index)"
                                    />
                                </div>
                                <div :class="[s.column, s.w33, s.paddingX]">
                                    <OrderValueBlock
                                        :exchange="currentPlacement ? currentPlacement.name : ''"
                                        :placement="placement"
                                        :pair="baseAssetSymbol && quoteAssetSymbol ? `${baseAssetSymbol}/${quoteAssetSymbol}` : ''"
                                        :fee="multiaccountOrderFormData.fees[index]"
                                        :quoted-fee="multiaccountOrderFormData.quotedFees[index]"
                                        :side="activeSide.value"
                                        :order-value="multiaccountOrderFormData.orderValues[index]"
                                        :fee-asset="commissionAsset"
                                        :fees-rate="storeData.feesRate"
                                        :quantity-precision="quantityPrecision"
                                        order-type="limit"
                                        :need-background="false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="[s.row, s.w100, s.justifyContentEnd, s.mtExtraLarge, s.buttonsContainer]">
                    <Button
                        @click="$modal.hide(uniqueId)"
                        :class="s.cancelButton"
                        button-type="info"
                    >
                        <template>
                            cancel
                        </template>
                    </Button>
                    <Button
                        @click="performMultiaccountOrder"
                        :class="s.w25"
                        button-type="primary"
                    >
                        <template>
                            {{ `place ${selectedOrdersNumber} orders` }}
                        </template>
                    </Button>
                </div>
            </div>
        </div>
        <modal
            :name="`confirmMarginOrder${uniqueComponentId}`"
            @before-open="isConfirmMarginOrderModalOpened = true"
            @before-close="isConfirmMarginOrderModalOpened = false"
        >
            <div :class="s.confirmMarginOrderModal">
                <div :class="s.header">
                    <span :class="s.buySellText">
                        {{ activeSide.value }}
                    </span>
                    <span :class="[s.headerInfoText, activeSide.value === OrdersSides.BUY.value ? s.green : s.red]">
                        {{ pair }} Margin {{ activeSide.value === OrdersSides.BUY.value ? 'Long' : 'Short' }} {{ selectedLeverage }}x
                    </span>
                </div>
                <div :class="s.infoBlock">
                    <Icon
                        icon="info"
                        :class="s.infoIcon"
                    />
                    <span :class="s.infoText">
                        Interest starts accruing as soon as a margin order is placed, regardless of whether it gets filled, since the necessary funds are borrowed at the time of placement.
                    </span>
                </div>
                <div :class="s.dataBlock">
                    <div :class="s.dataHeader">
                        <span :class="s.dataHeaderText">
                            {{ activeSide.value }} MARGIN {{ selectedLeverage }}X
                        </span>
                        <div :class="s.row">
                            <div :class="s.subRow">
                                <CryptoIcon
                                    :symbol="pair.split('/')[0]"
                                    :size="12"
                                />
                                <CryptoIcon
                                    :symbol="pair.split('/')[1]"
                                    :size="12"
                                    :class="s.secondCryptoIcon"
                                />
                                <span :class="s.placementAssetText">
                                    {{ pair }}
                                </span>
                            </div>
                            <div :class="s.subRow">
                                <PlacementIcon
                                    :placement="placement"
                                    :size="12"
                                />
                                <span :class="s.placementAssetText">
                                    {{ placement }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div :class="s.dataRow">
                        <span :class="s.dataFieldName">
                            order price
                        </span>
                        <AssetValue
                            :value="price"
                            :asset="quoteAssetSymbol"
                            :value-class="s.dataFieldValue"
                            :asset-class="s.dataFieldValueAsset"
                            :need-to-round="false"
                        />
                    </div>
                    <div :class="s.dataRow">
                        <span :class="s.dataFieldName">
                            quantity
                        </span>
                        <AssetValue
                            :value="quantity"
                            :asset="baseAssetSymbol"
                            :value-class="s.dataFieldValue"
                            :asset-class="s.dataFieldValueAsset"
                            :need-to-round="false"
                        />
                    </div>
                    <div :class="[s.dataRow, s.start]">
                        <span :class="s.dataFieldName">
                            order value
                        </span>
                        <div :class="s.subCol">
                            <AssetValue
                                :value="total"
                                :asset="quoteAssetSymbol"
                                :value-class="s.dataFieldValue"
                                :asset-class="s.dataFieldValueAsset"
                                :need-to-round="false"
                            />
                            <AssetValue
                                :value="orderValue"
                                :asset="`≈${quotationAssetCharacter}`"
                                :value-class="s.dataFieldValueQuotation"
                                :asset-class="s.dataFieldValueQuotation"
                                :need-to-round="false"
                            />
                        </div>
                    </div>
                    <div :class="s.dataRow">
                        <span :class="s.dataFieldName">
                            borrowing amount
                        </span>
                        <AssetValue
                            :value="
                                borrowings[activeSide.value === OrdersSides.BUY.value ? 'quote' : 'base']
                                    ? borrowings[activeSide.value === OrdersSides.BUY.value ? 'quote' : 'base'].borrowingAmount
                                    : 0
                            "
                            :asset="activeSide.value === OrdersSides.BUY.value ? quoteAssetSymbol : baseAssetSymbol"
                            :value-class="s.dataFieldValue"
                            :asset-class="s.dataFieldValueAsset"
                            :need-to-round="false"
                        />
                    </div>
                    <div :class="s.dataRow">
                        <span :class="s.dataFieldName">
                            max borrowing amount
                        </span>
                        <AssetValue
                            :value="
                                borrowings[activeSide.value === OrdersSides.BUY.value ? 'quote' : 'base']
                                    ? borrowings[activeSide.value === OrdersSides.BUY.value ? 'quote' : 'base'].borrowingMaxAmount
                                    : 0
                            "
                            :asset="activeSide.value === OrdersSides.BUY.value ? quoteAssetSymbol : baseAssetSymbol"
                            :value-class="s.dataFieldValue"
                            :asset-class="s.dataFieldValueAsset"
                            :need-to-round="false"
                        />
                    </div>
                    <div :class="s.dataRow">
                        <span :class="s.dataFieldName">
                            hourly rate
                        </span>
                        <span :class="s.dataFieldValue">
                            {{
                                borrowings[activeSide.value === OrdersSides.BUY.value ? 'quote' : 'base']
                                    ? borrowings[activeSide.value === OrdersSides.BUY.value ? 'quote' : 'base'].hourlyRate
                                    : 0
                            }}%
                        </span>
                    </div>
                </div>
                <div :class="s.buttonsRow">
                    <Button
                        button-type="infoNoBackground"
                        @click="closeModal"
                    >
                        <template>
                            Cancel
                        </template>
                    </Button>
                    <Button
                        @click="performOrder"
                    >
                        <template>
                            Confirm
                        </template>
                    </Button>
                </div>
            </div>
        </modal>
    </form>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/dist/validators.min';
import { mapGetters } from 'vuex';
import { nanoid } from 'nanoid';

import AssetValue from 'UI/AssetValue.vue';
import theme from 'Theme';
import Button from 'Control/Button.vue';
import orderValue from 'Mixins/orderValue';
import { OrderActiveFieldValues as fieldPriorities, OrdersSides } from 'Models/trading';
import { maxPrecision, divisible } from 'Models/fieldValidation';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import numberFormater from 'Mixins/numberFormater';
import { SET_UI } from 'Store/v2/MultiaccountOrder';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import SwitchControl from 'Control/Switch.vue';
import Checkbox from 'Control/Checkbox.vue';
import Icon from 'UI/Icon.vue';
import MoneyInput from 'Control/MoneyInput.vue';
import TradingApi from 'Apis/Trading';
import PlaceOrderRequest from 'Entities/orderRegistrator/PlaceOrderRequest';
import ApiError from 'Entities/ApiError';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import SelectLeverage from 'Modules/Trading/components/Futures/SelectLeverage.vue';
import MarginApi from 'Apis/Margin';
import CollateralRequest from 'Entities/privatePresenter/CollateralRequest';
import PublicDataApi from 'Apis/PublicData';
import AssetQuotationsRequest from 'Entities/publicPresenter/AssetQuotationsRequest';

import OrderValueBlock from './OrderValueBlock.vue';

export default {
    name: 'CryptoLimit',
    components: {
        SelectLeverage,
        AccountColorMarker,
        Icon,
        OrderValueBlock,
        AssetValue,
        Button,
        SwitchControl,
        Checkbox,
        MoneyInput,
    },
    mixins: [orderValue, numberFormater],
    props: {
        currentExpiry: {
            type: String,
            required: true,
        },
        isModal: {
            type: Boolean,
            default: false,
        },
        placement: {
            type: String,
            required: true,
        },
        pair: {
            type: String,
            required: true,
        },
        storeData: {
            type: undefined,
            required: true,
        },
        uniqueId: {
            type: String,
            required: true,
        },
        presetedQuantity: {
            type: Number,
            default: 0,
        },
        isMarginAvailable: {
            type: Boolean,
            default: false,
        },
        isMarginEnabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            OrdersSides,
            showTooltip: true,
            theme,

            priorityField: fieldPriorities.QUANTITY,
            selectedLeverage: 1,
            formData: {
                price: 0,
                total: 0,
                quantity: 0,
            },
            borrowings: {
                quote: undefined,
                base: undefined,
                cache: {
                    quote: undefined,
                    base: undefined,
                },
                quotations: undefined,
                quotationsCache: undefined,
            },
            isConfirmMarginOrderModalOpened: false,
            uniqueComponentId: '',
            multiaccountOrderFormData: {
                selectedAccountsIndexes: [],
                selectedAccountsSumTypes: [],
                selectedAccountsIsEditing: [],
                quantities: [],
                totals: [],
                prices: [],
                orderValues: [],
                fees: [],
                quotedFees: [],
                quantityValidationErrors: [],
                totalValidationErrors: [],
                totalValidationErrorTexts: [],
                quantityValidationErrorTexts: [],
            },

            quantityButtons: [
                {
                    title: 'Min',
                    callback: () => this.setQuantityMinValue(),
                },
                {
                    title: '25%',
                    callback: () => this.setQuantityPartOfFree(0.25),
                },
                {
                    title: '50%',
                    callback: () => this.setQuantityPartOfFree(0.5),
                },
                {
                    title: '75%',
                    callback: () => this.setQuantityPartOfFree(0.75),
                },
                {
                    title: 'Max',
                    callback: () => this.setQuantityPartOfFree(1),
                },
            ],
            totalButtons: [
                {
                    title: 'Min',
                    callback: () => this.setTotalMinValue(),
                },
                {
                    title: '25%',
                    callback: () => this.setTotalPartOfFree(0.25),
                },
                {
                    title: '50%',
                    callback: () => this.setTotalPartOfFree(0.5),
                },
                {
                    title: '75%',
                    callback: () => this.setTotalPartOfFree(0.75),
                },
                {
                    title: 'Max',
                    callback: () => this.setTotalPartOfFree(1),
                },
            ],
            priceButtons: [
                {
                    title: 'Bid',
                    callback: () => {
                        this.autoSetPrice('BID');
                    },
                },
                {
                    title: 'Market',
                    isLarge: true,
                    callback: () => {
                        this.autoSetPrice('MARKET');
                    },
                },
                {
                    title: 'Ask',
                    callback: () => {
                        this.autoSetPrice('ASK');
                    },
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
            activeAccountId: 'Accounts/activeAccountID',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        totalPlacementMarginBalance() {
            if (!this.isMarginAvailable || !this.isMarginEnabled) {
                return 0;
            }

            return this.$store.state.Balances.balances.reduce((accum, current) => {
                let temp = accum;
                if (
                    (current.free < 0
                    && current.assetSymbol === this.pair.split('/')[(this.activeSide.value === this.OrdersSides.BUY.value ? 1 : 0)]
                    && current.borrowable)
                    || (
                        current.placementName.toLowerCase() === this.placement.toLowerCase()
                        && current.accountId === this.activeAccountId
                        && current.borrowable
                        && current.assetSymbol === (this.activeSide.value === OrdersSides.BUY.value ? this.baseAssetSymbol : this.quoteAssetSymbol)
                    )
                ) {
                    return temp;
                }
                if (
                    current.placementName.toLowerCase() === this.placement.toLowerCase()
                    && current.accountId === this.activeAccountId
                    && current.borrowable
                ) {
                    let rate = 0;
                    const quotationIndex = current.quotationAssetSymbols.findIndex((q) => q === this.quotationAssetSymbol);
                    if (quotationIndex !== -1) {
                        rate = Number(current.quotations[quotationIndex]);
                    }
                    temp += (current.free * rate);
                }
                return temp;
            }, 0);
        },
        availableLeverages() {
            const leverages = this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair)?.placementLeverages;
            if (!leverages) {
                return [1];
            }
            return leverages.map((l) => Number(l.level)).sort((a, b) => a - b);
        },
        accountsOrder() {
            return [...(this.$store.state.Interface.config.ownedAccountsOrder || []), ...(this.$store.state.Interface.config.managedAccountsOrder || [])];
        },
        multiAccountsQuantityMaxValues() {
            return this.accountsList.map((a) => {
                const balance = this.$store.state.Balances.balances.find((b) => (
                    b.accountId === a.id
                    && b.placementName === this.currentPlacement?.name
                    && b.assetSymbol === this.baseAssetSymbol
                ));
                if (balance) {
                    return balance.free;
                }
                return 0;
            });
        },
        multiAccountsTotalMaxValues() {
            return this.accountsList.map((a) => {
                const balance = this.$store.state.Balances.balances.find((b) => (
                    b.accountId === a.id
                    && b.placementName === this.currentPlacement?.name
                    && b.assetSymbol === this.quoteAssetSymbol
                ));
                if (balance) {
                    return balance.free;
                }
                return 0;
            });
        },
        priceErrorText() {
            if (!this.$v.price.minValue) {
                return `Price value must be greater than ${this.priceRestriction.minValue.noExponents()}`;
            }
            if (!this.$v.price.maxValue) {
                return `Price value must be lower than ${this.priceRestriction.maxValue.noExponents()}`;
            }
            return '';
        },
        totalErrorText() {
            if (!this.$v.total.minValue) {
                return `Total value must be greater than ${this.totalMinValue.noExponents()}`;
            }
            if (!this.$v.total.maxValue) {
                return `Total value must be lower than ${this.totalMaxValue.noExponents()}`;
            }
            return '';
        },
        quantityErrorText() {
            if (!this.$v.quantity.maxValue) {
                return `Quantity value must be lower than ${this.quantityMaxValue.noExponents()}`;
            }
            return '';
        },
        quotationAssetCharacter() {
            return this.$store.getters['Assets/GET_QUOTATION_ASSET_CHARACTER'];
        },
        placementId() {
            return this.$store.getters['Placements/getPlacementIdByName'](this.placement);
        },
        quantityBalanceFree() {
            return this.storeData.quantityBalanceFree;
        },
        totalBalanceFree() {
            return this.storeData.totalBalanceFree;
        },
        baseAssetSymbol() {
            return this.pair.split('/')[0];
        },
        quoteAssetSymbol() {
            return this.pair.split('/')[1];
        },
        activeTerminalAssetPairPrecisionAmount() {
            return this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair)?.placementPrecisionQuantity ?? 8;
        },
        activeTerminalAssetPairPrecisionPrice() {
            return this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair)?.placementPrecisionPrice ?? 8;
        },
        activeSide() {
            return this.storeData.activeSide;
        },
        commissionAsset() {
            return this.storeData.commissionAsset;
        },
        quantityRestriction() {
            return this.storeData.quantityRestriction;
        },
        priceRestriction() {
            return this.storeData.priceRestriction;
        },
        totalRestriction() {
            return this.storeData.totalRestriction;
        },
        quotationAssetPrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
        accountsList() {
            return this.$store.state.Accounts.accounts
                .filter((a) => a.id !== this.$store.getters['Accounts/activeAccountID'])
                .sort(({ id: a }, { id: b }) => {
                    return this.accountsOrder.indexOf(a) - this.accountsOrder.indexOf(b);
                })
                .sort(({ roleName: a }, { roleName: b }) => {
                    if (a === 'owner' && b !== 'owner') {
                        return -1;
                    }
                    if (a !== 'owner' && b === 'owner') {
                        return 1;
                    }
                    return 0;
                });
        },
        currentPlacement() {
            return this.$store.state.Placements.placements.find((p) => p.id === this.placementId);
        },
        newCommission() {
            if (this.currentPlacement) {
                if (this.currentExpiry === 'GTC') {
                    return this.activeSide.value === 'SELL' ? this.total * Number(this.currentPlacement.commissionMaker) * 0.01 : this.quantity * Number(this.currentPlacement.commissionMaker) * 0.01;
                }
                return this.activeSide.value === 'SELL' ? this.total * Number(this.currentPlacement.commissionTaker) * 0.01 : this.quantity * Number(this.currentPlacement.commissionTaker) * 0.01;
            }
            return 0;
        },
        quotedCommission() {
            if (this.currentPlacement) {
                if (this.currentExpiry === 'GTC') {
                    return this.orderValue * Number(this.currentPlacement.commissionMaker) * 0.01;
                }
                return this.orderValue * Number(this.currentPlacement.commissionTaker) * 0.01;
            }
            return 0;
        },
        quantityMaxValue() {
            if (this.isMarginAvailable && this.isMarginEnabled) {
                let rate = Infinity;
                if (this.borrowings.quotations) {
                    rate = Number(this.borrowings.quotations.find(({ assetPairSymbol }) => assetPairSymbol.split('/')[0] === this.pair.split('/')[0])?.rate ?? Infinity);
                }
                return Math.min(
                    this.activeSide.value === OrdersSides.SELL.value ? (this.totalPlacementMarginBalance / rate) : Infinity,
                    this.quantityRestriction.maxValue,
                );
            }
            return Math.min(
                this.activeSide.value === OrdersSides.SELL.value ? this.quantityBalanceFree : Infinity,
                this.quantityRestriction.maxValue,
            );
        },
        totalMaxValue() {
            if (this.isMarginAvailable && this.isMarginEnabled) {
                let rate = Infinity;
                if (this.borrowings.quotations) {
                    rate = Number(this.borrowings.quotations.find(({ assetPairSymbol }) => assetPairSymbol.split('/')[0] === this.pair.split('/')[1])?.rate ?? Infinity);
                }
                return Math.min(
                    this.activeSide.value === OrdersSides.SELL.value ? (this.totalPlacementMarginBalance / rate) : Infinity,
                    this.quantityRestriction.maxValue,
                );
            }
            return Math.min(
                this.activeSide.value === OrdersSides.BUY.value ? this.totalBalanceFree : Infinity,
                this.totalRestriction.maxValue,
            );
        },
        quantityPrecision() {
            return Math.min(
                this.activeTerminalAssetPairPrecisionAmount,
                this.quantityRestriction.stepSize ? this.quantityRestriction.stepSize.getPrecision() : Infinity,
            );
        },
        pricePrecision() {
            return Math.min(
                this.activeTerminalAssetPairPrecisionPrice,
                this.priceRestriction.stepSize ? this.priceRestriction.stepSize.getPrecision() : Infinity,
            );
        },
        totalCustomValidationErrors() {
            return {
                maxValue:
                    this.totalBalanceFree < this.total
                        ? 'Not enough funds to place an order'
                        : `Total field must be below ${this.totalRestriction.maxValue.noExponents()}`,
            };
        },
        isQuantityPriority() {
            return this.priorityField === fieldPriorities.QUANTITY;
        },
        quantity: {
            get() {
                const result = this.truncateNumber(Number(this.isQuantityPriority ? this.formData.quantity : this.total / this.price) || 0, this.quantityPrecision);
                return result === Infinity ? 0 : result;
            },
            set(quantity) {
                this.formData.quantity = this.truncateNumber(Number(quantity) || 0, this.quantityPrecision);
                this.priorityField = fieldPriorities.QUANTITY;
            },
        },
        total: {
            get() {
                return this.truncateNumber(Number(this.isQuantityPriority ? this.quantity * this.price : this.formData.total) || 0, this.pricePrecision);
            },
            set(total) {
                this.formData.total = this.truncateNumber(Number(total) || 0, this.pricePrecision);
                this.priorityField = fieldPriorities.TOTAL;
            },
        },
        price: {
            get() {
                return this.formData.price;
            },
            set(value) {
                this.formData.price = value;
            },
        },
        bidPrice() {
            return this.storeData.bidPrice;
        },
        askPrice() {
            return this.storeData.askPrice;
        },
        lastMarketPrice() {
            return this.storeData.marketPrice;
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        quantityMinValue() {
            // TODO: костыль для маленьких ордеров на тестовом аккаунте для тестового окружения (убрать позже)
            if (this.currentUser && (this.currentUser.id === 'UZHMN9A2Q' || this.currentUser.id === 'UQELMU9GE')) {
                return 0;
            }
            const baseAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.baseAssetSymbol);
            if (baseAsset) {
                const baseAssetRate = Number((this.$store.state.Assets.quotations.get(baseAsset.symbol)?.USD ?? 0));
                if (baseAssetRate !== 0) {
                    return (25 / baseAssetRate).floor(this.quantityPrecision);
                }
                return 0;
            }
            return 0;
        },
        totalMinValue() {
            // TODO: костыль для маленьких ордеров на тестовом аккаунте для тестового окружения (убрать позже)
            if (this.currentUser && (this.currentUser.id === 'UZHMN9A2Q' || this.currentUser.id === 'UQELMU9GE')) {
                return 0;
            }
            const quoteAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.quoteAssetSymbol);
            if (quoteAsset) {
                const quoteAssetRate = (this.$store.state.Assets.quotations.get(quoteAsset.symbol)?.USD ?? 0);
                if (quoteAssetRate !== 0) {
                    return (25 / quoteAssetRate).floor(this.pricePrecision);
                }
                return 0;
            }
            return 0;
        },
        isQuantityButtonsDisabled() {
            if (this.isMarginAvailable && this.isMarginEnabled) {
                return this.totalPlacementMarginBalance <= 0;
            }
            if (this.activeSide.value === this.OrdersSides.BUY.value) {
                return this.totalBalanceFree === 0;
            }
            return this.quantityBalanceFree === 0;
        },
        isTotalButtonsDisabled() {
            if (this.isMarginAvailable && this.isMarginEnabled) {
                return this.totalPlacementMarginBalance <= 0;
            }
            if (this.activeSide.value === this.OrdersSides.BUY.value) {
                return this.totalBalanceFree === 0;
            }
            return this.quantityBalanceFree === 0;
        },
        disabledQuantityButtonsIndexes() {
            const result = [];
            let balance = 0;
            if (this.isMarginAvailable && this.isMarginEnabled) {
                let rate = Infinity;
                if (this.borrowings.quotations) {
                    rate = Number(
                        this.borrowings.quotations
                            .find(({ assetPairSymbol }) => (
                                assetPairSymbol.split('/')[0] === this.pair.split('/')[(this.activeSide.value === this.OrdersSides.BUY.value ? 1 : 0)]
                            ))?.rate ?? Infinity,
                    );
                }
                balance = this.totalPlacementMarginBalance / rate;
            } else {
                balance = this.activeSide.value === this.OrdersSides.BUY.value
                    ? this.totalBalanceFree
                    : this.quantityBalanceFree;
            }
            const minValue = this.activeSide.value === this.OrdersSides.BUY.value ? this.totalMinValue : this.quantityMinValue;
            if (balance * 0.25 < minValue) {
                result.push(1);
            }
            if (balance * 0.5 < minValue) {
                result.push(2);
            }
            if (balance * 0.75 < minValue) {
                result.push(3);
            }
            if (balance < minValue) {
                result.push(4);
            }
            return result;
        },
        disabledTotalButtonsIndexes() {
            const result = [];
            let balance = 0;
            if (this.isMarginAvailable && this.isMarginEnabled) {
                let rate = Infinity;
                if (this.borrowings.quotations) {
                    rate = Number(
                        this.borrowings.quotations
                            .find(({ assetPairSymbol }) => (
                                assetPairSymbol.split('/')[0] === this.pair.split('/')[(this.activeSide.value === this.OrdersSides.BUY.value ? 1 : 0)]
                            ))?.rate ?? Infinity,
                    );
                }
                balance = this.totalPlacementMarginBalance / rate;
            } else {
                balance = this.activeSide.value === this.OrdersSides.BUY.value
                    ? this.totalBalanceFree
                    : this.quantityBalanceFree;
            }
            const minValue = this.activeSide.value === this.OrdersSides.BUY.value ? this.totalMinValue : this.quantityMinValue;
            if (balance * 0.25 < minValue) {
                result.push(1);
            }
            if (balance * 0.5 < minValue) {
                result.push(2);
            }
            if (balance * 0.75 < minValue) {
                result.push(3);
            }
            if (balance < minValue) {
                result.push(4);
            }
            return result;
        },
        quantityBalance() {
            return this.$store.state.Balances.balances?.find((b) => b.assetSymbol === this.baseAssetSymbol && b.placementId === this.placementId);
        },
        totalBalance() {
            return this.$store.state.Balances.balances?.find((b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId === this.placementId);
        },
        selectedOrdersNumber() {
            return this.multiaccountOrderFormData.selectedAccountsIndexes.reduce((accum, current) => {
                if (current) {
                    return accum + 1;
                }
                return accum;
            }, 1);
        },
        firstManagedAccountIndex() {
            return this.accountsList.findIndex(({ roleName }) => roleName !== 'owner');
        },
        quantityType() {
            if (!this.$store.getters['Placements/activeTerminalPlacement']) {
                return 'base';
            }
            return this.$store.getters['Placements/activeTerminalPlacement'].orderQuantityTypes[`LIMIT_${this.currentExpiry}`][this.isBuy ? 'BUY' : 'SELL'];
        },
        isBuy() {
            return this.activeSide.value === OrdersSides.BUY.value;
        },
    },
    validations() {
        return {
            quantity: {
                required,
                maxValue: maxValue(this.quantityMaxValue),
                maxPrecision: maxPrecision(this.quantityPrecision),
                divisible: divisible(this.quantityRestriction.stepSize || 0),
            },
            price: {
                required,
                minValue: minValue(this.priceRestriction.minValue),
                maxValue: maxValue(this.priceRestriction.maxValue),
                maxPrecision: maxPrecision(this.pricePrecision),
                divisible: divisible(this.priceRestriction.stepSize || 0),
            },
            total: {
                required,
                minValue: minValue(this.totalMinValue),
                maxValue: maxValue(this.totalMaxValue),
                maxPrecision: maxPrecision(this.pricePrecision),
            },
        };
    },
    methods: {
        closeModal() {
            this.$modal.hide(`confirmMarginOrder${this.uniqueComponentId}`);
        },
        async getPairQuotations() {
            if (
                !this.isMarginAvailable
                || !this.isMarginEnabled
                || !this.pair
                || !this.quotationAssetSymbol
            ) {
                return;
            }

            const payload = {
                assetSymbols: this.pair.split('/'),
                quotationSymbol: this.quotationAssetSymbol,
            };
            if (
                this.borrowings.quotationsCache
                && JSON.stringify(this.borrowings.quotationsCache) === JSON.stringify(payload)
            ) {
                return;
            }
            this.borrowings.quotationsCache = payload;

            try {
                const { data: quotations } = await PublicDataApi.publicGetAssetQuotations(new AssetQuotationsRequest(payload));
                this.borrowings.quotations = quotations;
            } catch {
                this.borrowings.quotations = undefined;
            }
        },
        async getQuoteBorrowing() {
            if (!this.isMarginEnabled || !this.isMarginAvailable) {
                return;
            }

            const placementTag = this.$store.state.Placements.placementNamesToPlacementTags.get(this.placement.toUpperCase());
            const assetSymbol = this.pair.split('/')[1];
            if (
                !this.activeAccountId
                || !placementTag
                || !assetSymbol
            ) {
                return;
            }
            const payload = {
                accountId: this.activeAccountId,
                assetSymbol,
                placementTag,
            };
            if (
                this.borrowings.cache.quote
                && JSON.stringify(this.borrowings.cache.quote) === JSON.stringify(payload)
            ) {
                return;
            }
            this.borrowings.cache.quote = payload;

            try {
                const { data: borrowings } = await MarginApi.privateMarginGetCollateral(new CollateralRequest(payload));
                if (borrowings) {
                    this.borrowings.quote = borrowings;
                } else {
                    this.borrowings.quote = undefined;
                }
            } catch {
                this.borrowings.quote = undefined;
            }
        },
        async getBaseBorrowing() {
            if (!this.isMarginEnabled || !this.isMarginAvailable) {
                return;
            }

            const placementTag = this.$store.state.Placements.placementNamesToPlacementTags.get(this.placement.toUpperCase());
            const assetSymbol = this.pair.split('/')[0];
            if (
                !this.activeAccountId
                || !placementTag
                || !assetSymbol
            ) {
                return;
            }
            const payload = {
                accountId: this.activeAccountId,
                assetSymbol,
                placementTag,
            };
            if (
                this.borrowings.cache.base
                && JSON.stringify(this.borrowings.cache.base) === JSON.stringify(payload)
            ) {
                return;
            }
            this.borrowings.cache.base = payload;

            try {
                const { data: borrowings } = await MarginApi.privateMarginGetCollateral(new CollateralRequest(payload));
                if (borrowings) {
                    this.borrowings.base = borrowings;
                } else {
                    this.borrowings.base = undefined;
                }
            } catch {
                this.borrowings.base = undefined;
            }
        },
        getAccountColor(account) {
            if (!account.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return account.color;
        },
        validateMultiOrder() {
            this.multiaccountOrderFormData.totalValidationErrors = [];
            this.multiaccountOrderFormData.quantityValidationErrors = [];
            this.multiaccountOrderFormData.totalValidationErrorTexts = [];
            this.multiaccountOrderFormData.quantityValidationErrorTexts = [];

            if (this.activeSide.value === this.OrdersSides.BUY.value) {
                this.multiaccountOrderFormData.totals.forEach((t, i) => {
                    if (t < this.totalMinValue && this.multiaccountOrderFormData.selectedAccountsIndexes[i]) {
                        this.multiaccountOrderFormData.totalValidationErrors[i] = true;
                        this.multiaccountOrderFormData.totalValidationErrorTexts[i] = `Total value must be greater than ${this.totalMinValue}`;
                    }
                });
                if (this.multiaccountOrderFormData.totalValidationErrors.length > 0) {
                    return false;
                }
                this.multiaccountOrderFormData.totals.forEach((t, i) => {
                    if (t > this.multiAccountsTotalMaxValues[i] && this.multiaccountOrderFormData.selectedAccountsIndexes[i]) {
                        this.multiaccountOrderFormData.totalValidationErrors[i] = true;
                        this.multiaccountOrderFormData.totalValidationErrorTexts[i] = `Total value must be lower than ${this.multiAccountsTotalMaxValues[i]}`;
                    }
                });
                return this.multiaccountOrderFormData.totalValidationErrors.length === 0;
            }

            this.multiaccountOrderFormData.quantities.forEach((q, i) => {
                if (q < this.quantityMinValue && this.multiaccountOrderFormData.selectedAccountsIndexes[i]) {
                    this.multiaccountOrderFormData.quantityValidationErrors[i] = true;
                    this.multiaccountOrderFormData.quantityValidationErrorTexts[i] = `Quantity value must be greater than ${this.quantityMinValue}`;
                }
            });
            if (this.multiaccountOrderFormData.quantityValidationErrors.length > 0) {
                return false;
            }
            this.multiaccountOrderFormData.quantities.forEach((q, i) => {
                if (q > this.multiAccountsQuantityMaxValues[i] && this.multiaccountOrderFormData.selectedAccountsIndexes[i]) {
                    this.multiaccountOrderFormData.quantityValidationErrors[i] = true;
                    this.multiaccountOrderFormData.quantityValidationErrorTexts[i] = `Quantity value must be lower than ${this.multiAccountsQuantityMaxValues[i]}`;
                }
            });
            return this.multiaccountOrderFormData.quantityValidationErrors.length === 0;
        },
        toggleDemoAccountAlert() {
            if (this.$store.getters['Accounts/isActiveAccountDemo']) {
                this.$store.state.Accounts.showDemoAccountAlert = true;
                this.$store.commit('Accounts/SET_IS_DEMO_ACCOUNT_SHAKING');
            }
        },
        accountFreeBase(id) {
            const balance = this.$store.state.Balances.balances.find((b) => b.accountId === id && b.placementId === this.placementId && b.assetSymbol === this.baseAssetSymbol);
            if (balance) {
                return balance.free;
            }
            return 0;
        },
        accountFreeQuote(id) {
            const balance = this.$store.state.Balances.balances.find((b) => b.accountId === id && b.placementId === this.placementId && b.assetSymbol === this.quoteAssetSymbol);
            if (balance) {
                return balance.free;
            }
            return 0;
        },
        openMultiaccountOrderModal() {
            this.$store.commit(SET_UI({ quantity: this.quantity, price: this.price, total: this.total }));
            this.$modal.show(this.uniqueId);
        },
        setQuantity(num) {
            this.quantity = Number(num.toFixed(this.quantityPrecision));
        },
        setMultiaccountQuantity(num, index) {
            this.multiaccountOrderFormData.quantities[index] = num.floor(this.quantityPrecision);
            this.multiaccountOrderFormData.totals[index] = (this.multiaccountOrderFormData.prices[index] * this.multiaccountOrderFormData.quantities[index]).floor(this.pricePrecision);
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        setPrice(num) {
            this.price = Number(num.toFixed(this.pricePrecision));
        },
        setMultiaccountPrice(num, index) {
            this.multiaccountOrderFormData.prices[index] = num.floor(this.pricePrecision);
            this.multiaccountOrderFormData.totals[index] = (this.multiaccountOrderFormData.quantities[index] * this.multiaccountOrderFormData.prices[index]).floor(this.pricePrecision);
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        setTotal(num) {
            this.total = Number(num.toFixed(this.pricePrecision));
        },
        setMultiaccountTotal(num, index) {
            this.multiaccountOrderFormData.totals[index] = num.floor(this.pricePrecision);
            if (this.multiaccountOrderFormData.prices[index] !== 0) {
                this.multiaccountOrderFormData.quantities[index] = (this.multiaccountOrderFormData.totals[index] / this.multiaccountOrderFormData.prices[index]).floor(this.quantityPrecision);
            } else {
                this.multiaccountOrderFormData.quantities[index] = 0;
            }
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        setQuantityMinValue() {
            this.total = this.totalMinValue;
            if (this.total === 0) {
                this.total = 1 / 10 ** this.pricePrecision;
            }
            if (this.quantity === 0) {
                this.quantity = 1 / 10 ** this.quantityPrecision;
            }
            this.$refs.TotalInput.cleanFocusButtons();
        },
        setTotalMinValue() {
            this.total = this.totalMinValue;
            if (this.total === 0) {
                this.total = 1 / 10 ** this.pricePrecision;
            }
            if (this.quantity === 0) {
                this.quantity = 1 / 10 ** this.quantityPrecision;
            }
            this.$refs.QuantityInput.cleanFocusButtons();
        },
        setQuantityPartOfFree(partRatio) {
            if (this.isMarginAvailable && this.isMarginEnabled) {
                if (this.activeSide.value === this.OrdersSides.BUY.value) {
                    let rate = Infinity;
                    if (this.borrowings.quotations) {
                        rate = Number(this.borrowings.quotations.find(({ assetPairSymbol }) => assetPairSymbol.split('/')[0] === this.pair.split('/')[1])?.rate ?? Infinity);
                    }
                    this.total = Number((this.totalPlacementMarginBalance / rate) * partRatio).floor(this.pricePrecision);
                } else {
                    let rate = Infinity;
                    if (this.borrowings.quotations) {
                        rate = Number(this.borrowings.quotations.find(({ assetPairSymbol }) => assetPairSymbol.split('/')[0] === this.pair.split('/')[0])?.rate ?? Infinity);
                    }
                    this.quantity = Number((this.totalPlacementMarginBalance / rate) * partRatio).floor(this.quantityPrecision);
                }
            } else if (this.activeSide.value === this.OrdersSides.BUY.value) {
                this.total = Number(this.totalBalanceFree * partRatio).floor(this.pricePrecision);
            } else {
                this.quantity = Number(this.quantityBalanceFree * partRatio).floor(this.quantityPrecision);
            }
            this.$refs.TotalInput.cleanFocusButtons();
        },
        setTotalPartOfFree(partRatio) {
            if (this.isMarginAvailable && this.isMarginEnabled) {
                if (this.activeSide.value === this.OrdersSides.BUY.value) {
                    let rate = Infinity;
                    if (this.borrowings.quotations) {
                        rate = Number(this.borrowings.quotations.find(({ assetPairSymbol }) => assetPairSymbol.split('/')[0] === this.pair.split('/')[1])?.rate ?? Infinity);
                    }
                    this.total = Number((this.totalPlacementMarginBalance / rate) * partRatio).floor(this.pricePrecision);
                } else {
                    let rate = Infinity;
                    if (this.borrowings.quotations) {
                        rate = Number(this.borrowings.quotations.find(({ assetPairSymbol }) => assetPairSymbol.split('/')[0] === this.pair.split('/')[0])?.rate ?? Infinity);
                    }
                    this.quantity = Number((this.totalPlacementMarginBalance / rate) * partRatio).floor(this.quantityPrecision);
                }
            } else if (this.activeSide.value === this.OrdersSides.BUY.value) {
                this.total = Number(this.totalBalanceFree * partRatio).floor(this.pricePrecision);
            } else {
                this.quantity = Number(this.quantityBalanceFree * partRatio).floor(this.quantityPrecision);
            }
            this.$refs.QuantityInput.cleanFocusButtons();
        },
        async performOrder() {
            setTimeout(() => {
                this.showTooltip = true;
            }, 100);

            this.$v.$touch();
            if (!this.$v.$invalid) {
                if (this.isMarginAvailable && this.isMarginEnabled && !this.isConfirmMarginOrderModalOpened) {
                    this.$modal.show(`confirmMarginOrder${this.uniqueComponentId}`);
                    return;
                }

                this.$store.commit(SET_LOADING_ON(undefined));
                let requestData;
                if (this.quantityType === 'base') {
                    requestData = {
                        accountId: this.$store.getters['Accounts/activeAccountID'],
                        assetPairId: this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair)?.id,
                        marketPrice: Number(this.lastMarketPrice),
                        placementId: this.placementId,
                        side: this.activeSide.value,
                        quantity: Number(this.quantity),
                        price: Number(this.price),
                        timeInForce: this.currentExpiry,
                        type: 'LIMIT',
                        leverage: this.isMarginAvailable && this.isMarginEnabled ? this.selectedLeverage : undefined,
                    };
                } else {
                    requestData = {
                        accountId: this.$store.getters['Accounts/activeAccountID'],
                        assetPairId: this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair)?.id,
                        marketPrice: Number(this.lastMarketPrice),
                        placementId: this.placementId,
                        side: this.activeSide.value,
                        quoteQuantity: Number(this.total),
                        price: Number(this.price),
                        timeInForce: this.currentExpiry,
                        type: 'LIMIT',
                        leverage: this.isMarginAvailable && this.isMarginEnabled ? this.selectedLeverage : undefined,
                    };
                }
                try {
                    const { data: order } = await TradingApi.privateOrdersPlaceOrder(new PlaceOrderRequest(requestData));
                    await this.$store.dispatch('Orders/History/updateOrdersBySocket', order.serialize());
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during place order');
                    }
                } finally {
                    this.isSubmitDisabled = false;
                    this.clearForm();
                    this.$v.$reset();
                    this.$refs.TotalInput.cleanFocusButtons();
                    this.$refs.QuantityInput.cleanFocusButtons();
                    this.$refs.PriceInput.cleanFocusButtons();
                    this.$modal.hide(`confirmMarginOrder${this.uniqueComponentId}`);
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            }
        },
        async performMultiaccountOrder() {
            setTimeout(() => {
                this.showTooltip = true;
            }, 100);

            this.$v.$touch();
            const isMultiOrderValid = this.validateMultiOrder();
            if (!this.$v.$invalid && isMultiOrderValid) {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    let requestData;
                    if (this.quantityType === 'base') {
                        requestData = {
                            accountId: this.$store.getters['Accounts/activeAccountID'],
                            assetPairId: this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair)?.id,
                            marketPrice: Number(this.lastMarketPrice),
                            placementId: this.placementId,
                            side: this.activeSide.value,
                            quantity: Number(this.quantity),
                            price: Number(this.price),
                            timeInForce: this.currentExpiry,
                            type: 'LIMIT',
                        };
                    } else {
                        requestData = {
                            accountId: this.$store.getters['Accounts/activeAccountID'],
                            assetPairId: this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair)?.id,
                            marketPrice: Number(this.lastMarketPrice),
                            placementId: this.placementId,
                            side: this.activeSide.value,
                            quoteQuantity: Number(this.total),
                            price: Number(this.price),
                            timeInForce: this.currentExpiry,
                            type: 'LIMIT',
                        };
                    }
                    try {
                        await TradingApi.privateOrdersPlaceOrder(new PlaceOrderRequest(requestData));
                    } catch (error) {
                        if (error instanceof ApiError) {
                            await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during place order');
                        }
                    }
                    this.accountsList.forEach((account, index) => {
                        if (this.multiaccountOrderFormData.selectedAccountsIndexes[index]) {
                            let extraRequestData;
                            if (this.quantityType === 'base') {
                                extraRequestData = {
                                    accountId: account.id,
                                    assetPairId: this.$store.getters['AssetPairs/GET_ASSET_PAIRS'].find(({ symbol }) => symbol === this.pair)?.id,
                                    marketPrice: Number(this.lastMarketPrice),
                                    placementId: this.placementId,
                                    side: this.activeSide.value,
                                    quantity: Number(this.multiaccountOrderFormData.quantities[index]),
                                    price: Number(this.multiaccountOrderFormData.prices[index]),
                                    timeInForce: this.currentExpiry,
                                    type: 'LIMIT',
                                };
                            } else {
                                extraRequestData = {
                                    accountId: account.id,
                                    assetPairId: this.$store.getters['AssetPairs/GET_ASSET_PAIRS'].find(({ symbol }) => symbol === this.pair)?.id,
                                    marketPrice: Number(this.lastMarketPrice),
                                    placementId: this.placementId,
                                    side: this.activeSide.value,
                                    quoteQuantity: Number(this.multiaccountOrderFormData.totals[index]),
                                    price: Number(this.multiaccountOrderFormData.prices[index]),
                                    timeInForce: this.currentExpiry,
                                    type: 'LIMIT',
                                };
                            }
                            TradingApi.privateOrdersPlaceOrder(new PlaceOrderRequest(extraRequestData))
                                .catch((error) => {
                                    if (error instanceof ApiError) {
                                        this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during place order');
                                    }
                                });
                        }
                    });
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                    this.$modal.hide(this.uniqueId);
                    this.$v.$reset();
                }
            }
        },
        clearForm() {
            this.quantity = 0;
            this.total = 0;
            this.price = 0;
        },
        autoSetPrice(type) {
            switch (type) {
                case 'BID':
                    this.price = this.bidPrice.ceil(this.pricePrecision);
                    break;
                case 'ASK':
                    this.price = this.askPrice.ceil(this.pricePrecision);
                    break;
                case 'MARKET':
                    this.price = this.lastMarketPrice.ceil(this.pricePrecision);
                    break;
                default:
                    throw 'Unsupported auto set price type';
            }
        },
        changeSwitchValue(index) {
            this.multiaccountOrderFormData.selectedAccountsSumTypes[index] = !this.multiaccountOrderFormData.selectedAccountsSumTypes[index];
            this.calculateAccountsFormData();
            this.$forceUpdate();
        },
        calculateAccountsFormData() {
            this.accountsList.forEach((account, index) => {
                if (!this.multiaccountOrderFormData.selectedAccountsIsEditing[index]) {
                    if (this.multiaccountOrderFormData.selectedAccountsSumTypes[index]) {
                        this.multiaccountOrderFormData.prices[index] = this.price;
                        this.multiaccountOrderFormData.quantities[index] = this.quantity;
                        this.multiaccountOrderFormData.totals[index] = this.total;
                    } else {
                        let balance;
                        if (this.activeSide.value.toUpperCase() === 'BUY') {
                            balance = this.$store.state.Balances.balances.find((b) => b.placementId === this.placementId && b.accountId === account.id && b.assetSymbol === this.quoteAssetSymbol);
                        } else {
                            balance = this.$store.state.Balances.balances.find((b) => b.placementId === this.placementId && b.accountId === account.id && b.assetSymbol === this.baseAssetSymbol);
                        }
                        this.multiaccountOrderFormData.prices[index] = this.price;
                        // calculate quantity and total
                        if (!balance) {
                            this.multiaccountOrderFormData.quantities[index] = 0;
                            this.multiaccountOrderFormData.totals[index] = 0;
                        } else if (this.activeSide.value.toUpperCase() === 'BUY') {
                            if (this.totalBalanceFree !== 0) {
                                this.multiaccountOrderFormData.totals[index] = ((this.total * balance.free) / this.totalBalanceFree).floor(this.pricePrecision);
                                if (this.multiaccountOrderFormData.totals[index] > balance.free) {
                                    this.multiaccountOrderFormData.totals[index] = balance.free;
                                }
                            } else {
                                this.multiaccountOrderFormData.totals[index] = 0;
                            }
                            if (this.price !== 0) {
                                this.multiaccountOrderFormData.quantities[index] = (this.multiaccountOrderFormData.totals[index] / this.price).floor(this.quantityPrecision);
                            } else {
                                this.multiaccountOrderFormData.quantities[index] = 0;
                            }
                        } else if (this.activeSide.value.toUpperCase() === 'SELL') {
                            if (this.quantityBalanceFree !== 0) {
                                this.multiaccountOrderFormData.quantities[index] = ((this.quantity * balance.free) / this.quantityBalanceFree).floor(this.quantityPrecision);
                                if (this.multiaccountOrderFormData.quantities[index] > balance.free) {
                                    this.multiaccountOrderFormData.quantities[index] = balance.free;
                                }
                            } else {
                                this.multiaccountOrderFormData.quantities[index] = 0;
                            }
                            this.multiaccountOrderFormData.totals[index] = (this.multiaccountOrderFormData.quantities[index] * this.price).floor(this.pricePrecision);
                        }
                    }
                    this.recalculateOrderDetails(index);
                }
            });
        },
        recalculateOrderDetails(index) {
            // calculate order value
            const quoteAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.quoteAssetSymbol);
            const quoteRate = quoteAsset && this.$store.state.Assets.quotations.has(quoteAsset.symbol)
                ? this.$store.state.Assets.quotations.get(quoteAsset.symbol)[this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL']]
                : 0;
            this.multiaccountOrderFormData.orderValues[index] = quoteRate * this.multiaccountOrderFormData.totals[index];
            // calculate fee
            if (this.currentPlacement) {
                if (this.currentExpiry === 'GTC') {
                    this.multiaccountOrderFormData.fees[index] = this.activeSide.value === 'SELL' ? this.multiaccountOrderFormData.totals[index] * Number(this.currentPlacement.commissionMaker) * 0.01 : this.multiaccountOrderFormData.quantities[index] * Number(this.currentPlacement.commissionMaker) * 0.01;
                } else {
                    this.multiaccountOrderFormData.fees[index] = this.activeSide.value === 'SELL' ? this.multiaccountOrderFormData.totals[index] * Number(this.currentPlacement.commissionTaker) * 0.01 : this.multiaccountOrderFormData.quantities[index] * Number(this.currentPlacement.commissionTaker) * 0.01;
                }
            } else {
                this.multiaccountOrderFormData.fees[index] = 0;
            }
            // calculate quoted fee
            if (this.currentPlacement) {
                if (this.currentExpiry === 'GTC') {
                    this.multiaccountOrderFormData.quotedFees[index] = this.multiaccountOrderFormData.orderValues[index] * Number(this.currentPlacement.commissionMaker) * 0.01;
                } else {
                    this.multiaccountOrderFormData.quotedFees[index] = this.multiaccountOrderFormData.orderValues[index] * Number(this.currentPlacement.commissionTaker) * 0.01;
                }
            } else {
                this.multiaccountOrderFormData.quotedFees[index] = 0;
            }
        },
        multiaccountQuantityMaxValue(index) {
            const balance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.baseAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const balanceFree = balance ? balance.free : 0;
            return Math.min(
                this.activeSide.value === OrdersSides.SELL.value ? balanceFree : Infinity,
                this.quantityRestriction.maxValue,
            );
        },
        multiaccountTotalMaxValue(index) {
            const balance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const balanceFree = balance ? balance.free : 0;
            return Math.min(
                this.activeSide.value === OrdersSides.BUY.value ? balanceFree : Infinity,
                this.totalRestriction.maxValue,
            );
        },
        makeMultiaccountQuantityButtons(index) {
            return [
                {
                    title: 'Min',
                    callback: () => this.autoSetMultiaccountQuantityMinValue(index),
                },
                {
                    title: '25%',
                    callback: () => this.autoSetMultiaccountQuantity(0.25, index),
                },
                {
                    title: '50%',
                    callback: () => this.autoSetMultiaccountQuantity(0.5, index),
                },
                {
                    title: '75%',
                    callback: () => this.autoSetMultiaccountQuantity(0.75, index),
                },
                {
                    title: 'Max',
                    callback: () => this.autoSetMultiaccountQuantity(1, index),
                },
            ];
        },
        makeMultiaccountTotalButtons(index) {
            return [
                {
                    title: 'Min',
                    callback: () => this.autoSetMultiaccountTotalMinValue(index),
                },
                {
                    title: '25%',
                    callback: () => this.autoSetMultiaccountTotal(0.25, index),
                },
                {
                    title: '50%',
                    callback: () => this.autoSetMultiaccountTotal(0.5, index),
                },
                {
                    title: '75%',
                    callback: () => this.autoSetMultiaccountTotal(0.75, index),
                },
                {
                    title: 'Max',
                    callback: () => this.autoSetMultiaccountTotal(1, index),
                },
            ];
        },
        makeMultiaccountPriceButtons(index) {
            return [
                {
                    title: 'Bid',
                    callback: () => {
                        this.autoSetMultiaccountPrice('BID', index);
                    },
                },
                {
                    title: 'Market',
                    isLarge: true,
                    callback: () => {
                        this.autoSetMultiaccountPrice('MARKET', index);
                    },
                },
                {
                    title: 'Ask',
                    callback: () => {
                        this.autoSetMultiaccountPrice('ASK', index);
                    },
                },
            ];
        },
        autoSetMultiaccountPrice(type, index) {
            switch (type) {
                case 'BID':
                    this.multiaccountOrderFormData.prices[index] = this.bidPrice.ceil(this.pricePrecision);
                    break;
                case 'ASK':
                    this.multiaccountOrderFormData.prices[index] = this.askPrice.ceil(this.pricePrecision);
                    break;
                case 'MARKET':
                    this.multiaccountOrderFormData.prices[index] = this.lastMarketPrice.ceil(this.pricePrecision);
                    break;
                default:
                    throw 'Unsupported auto set price type';
            }
            this.multiaccountOrderFormData.totals[index] = (this.multiaccountOrderFormData.quantities[index] * this.multiaccountOrderFormData.prices[index]).floor(this.pricePrecision);
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        autoSetMultiaccountQuantityMinValue(index) {
            const baseAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.baseAssetSymbol);
            if (baseAsset) {
                const baseAssetRate = (this.$store.state.Assets.quotations.get(baseAsset.symbol)?.USD ?? 0);
                if (baseAssetRate !== 0) {
                    this.multiaccountOrderFormData.quantities[index] = (25 / baseAssetRate).floor(this.quantityPrecision);
                } else {
                    this.multiaccountOrderFormData.quantities[index] = 0;
                }
            } else {
                this.multiaccountOrderFormData.quantities[index] = 0;
            }
            this.multiaccountOrderFormData.totals[index] = (this.multiaccountOrderFormData.prices[index] * this.multiaccountOrderFormData.quantities[index]).floor(this.pricePrecision);
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        autoSetMultiaccountTotalMinValue(index) {
            const quoteAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.quoteAssetSymbol);
            if (quoteAsset) {
                const quoteAssetRate = (this.$store.state.Assets.quotations.get(quoteAsset.symbol)?.USD ?? 0);
                if (quoteAssetRate !== 0) {
                    this.multiaccountOrderFormData.totals[index] = (25 / quoteAssetRate).floor(this.pricePrecision);
                } else {
                    this.multiaccountOrderFormData.totals[index] = 0;
                }
            } else {
                this.multiaccountOrderFormData.totals[index] = 0;
            }
            if (this.multiaccountOrderFormData.prices[index] !== 0) {
                this.multiaccountOrderFormData.quantities[index] = (this.multiaccountOrderFormData.totals[index] / this.multiaccountOrderFormData.prices[index]).floor(this.quantityPrecision);
            } else {
                this.multiaccountOrderFormData.quantities[index] = 0;
            }
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        autoSetMultiaccountQuantity(multiplier, index) {
            const balance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.baseAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const balanceFree = balance ? balance.free : 0;
            this.multiaccountOrderFormData.quantities[index] = (balanceFree * multiplier).floor(this.quantityPrecision);
            this.multiaccountOrderFormData.totals[index] = (this.multiaccountOrderFormData.prices[index] * this.multiaccountOrderFormData.quantities[index]).floor(this.pricePrecision);
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        autoSetMultiaccountTotal(multiplier, index) {
            const balance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const balanceFree = balance ? balance.free : 0;
            this.multiaccountOrderFormData.totals[index] = (balanceFree * multiplier).floor(this.pricePrecision);
            if (this.multiaccountOrderFormData.prices[index] !== 0) {
                this.multiaccountOrderFormData.quantities[index] = (this.multiaccountOrderFormData.totals[index] / this.multiaccountOrderFormData.prices[index]).floor(this.quantityPrecision);
            } else {
                this.multiaccountOrderFormData.quantities[index] = 0;
            }
            this.recalculateOrderDetails(index);
            this.$forceUpdate();
        },
        isMultiaccountQuantityButtonsDisabled(index) {
            const totalBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const quantityBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.baseAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const totalBalanceFree = totalBalance ? totalBalance.free : 0;
            const quantityBalanceFree = quantityBalance ? quantityBalance.free : 0;
            if (this.activeSide.value === this.OrdersSides.BUY.value) {
                return totalBalanceFree === 0;
            }
            return quantityBalanceFree === 0;
        },
        isMultiaccountTotalButtonsDisabled(index) {
            const totalBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const quantityBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.baseAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const totalBalanceFree = totalBalance ? totalBalance.free : 0;
            const quantityBalanceFree = quantityBalance ? quantityBalance.free : 0;
            if (this.activeSide.value === this.OrdersSides.BUY.value) {
                return totalBalanceFree === 0;
            }
            return quantityBalanceFree === 0;
        },
        getMultiaccountDisabledQuantityButtonsIndexes(index) {
            const totalBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const quantityBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.baseAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const totalBalanceFree = totalBalance ? totalBalance.free : 0;
            const quantityBalanceFree = quantityBalance ? quantityBalance.free : 0;
            const result = [];
            const balance = this.activeSide.value === this.OrdersSides.BUY.value ? totalBalanceFree : quantityBalanceFree;
            const minValue = this.activeSide.value === this.OrdersSides.BUY.value ? this.totalMinValue : this.quantityMinValue;
            if (balance * 0.25 < minValue) {
                result.push(1);
            }
            if (balance * 0.5 < minValue) {
                result.push(2);
            }
            if (balance * 0.75 < minValue) {
                result.push(3);
            }
            if (balance < minValue) {
                result.push(4);
            }
            return result;
        },
        getMultiaccountDisabledTotalButtonsIndexes(index) {
            const totalBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const quantityBalance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.baseAssetSymbol && b.placementId === this.placementId && b.accountId === this.accountsList[index].id);
            const totalBalanceFree = totalBalance ? totalBalance.free : 0;
            const quantityBalanceFree = quantityBalance ? quantityBalance.free : 0;
            const result = [];
            const balance = this.activeSide.value === this.OrdersSides.BUY.value ? totalBalanceFree : quantityBalanceFree;
            const minValue = this.activeSide.value === this.OrdersSides.BUY.value ? this.totalMinValue : this.quantityMinValue;
            if (balance * 0.25 < minValue) {
                result.push(1);
            }
            if (balance * 0.5 < minValue) {
                result.push(2);
            }
            if (balance * 0.75 < minValue) {
                result.push(3);
            }
            if (balance < minValue) {
                result.push(4);
            }
            return result;
        },
        handleAccountCheckbox(index) {
            const tempArray = [...this.multiaccountOrderFormData.selectedAccountsIndexes];
            tempArray[index] = !tempArray[index];
            this.multiaccountOrderFormData.selectedAccountsIndexes = tempArray;
            this.$forceUpdate();
        },
        handleEditCheckbox(index) {
            this.multiaccountOrderFormData.selectedAccountsIsEditing[index] = !this.multiaccountOrderFormData.selectedAccountsIsEditing[index];
            this.$forceUpdate();
        },
    },
    created() {
        document.addEventListener('click', () => {
            this.showTooltip = false;
        });

        this.accountsList.forEach((account, index) => {
            this.multiaccountOrderFormData.selectedAccountsSumTypes[index] = false;
            this.multiaccountOrderFormData.selectedAccountsIsEditing[index] = false;
        });
    },
    async mounted() {
        this.uniqueComponentId = nanoid(16);
        if (this.presetedQuantity) {
            this.quantity = this.presetedQuantity;
        }
        if (this.isModal) {
            const multiaccountOrderStateUi = this.$store.state.MultiaccountOrder.ui;
            this.price = multiaccountOrderStateUi.price;
            this.total = multiaccountOrderStateUi.total;
            this.quantity = multiaccountOrderStateUi.quantity;
            this.calculateAccountsFormData();
        }
        if (this.isMarginEnabled && this.isMarginAvailable) {
            this.selectedLeverage = 2;
        } else {
            this.selectedLeverage = 1;
        }
        await this.getQuoteBorrowing();
        await this.getBaseBorrowing();
        await this.getPairQuotations();
    },
    watch: {
        async quotationAssetSymbol() {
            await this.getPairQuotations();
        },
        async activeAccountId() {
            await this.getBaseBorrowing();
            await this.getQuoteBorrowing();
        },
        async pair() {
            await this.getBaseBorrowing();
            await this.getQuoteBorrowing();
            await this.getPairQuotations();
        },
        async isMarginEnabled(value) {
            if (value) {
                this.selectedLeverage = 2;
            } else {
                this.selectedLeverage = 1;
            }

            await this.getBaseBorrowing();
            await this.getQuoteBorrowing();
            await this.getPairQuotations();
        },
        async baseAssetSymbol() {
            this.clearForm();
        },
        async quoteAssetSymbol() {
            this.clearForm();
        },
        async placementId() {
            this.clearForm();

            await this.getBaseBorrowing();
            await this.getQuoteBorrowing();
        },
        quantity() {
            if (this.price === 0) {
                this.price = this.lastMarketPrice.ceil(this.pricePrecision);
            }

            if (this.isModal) {
                this.calculateAccountsFormData();
            }
            this.$emit('set-quantity', this.quantity);
            this.$v.quantity.$reset();
        },
        total() {
            if (this.price === 0) {
                this.price = this.lastMarketPrice.ceil(this.pricePrecision);
            }

            if (this.isModal) {
                this.calculateAccountsFormData();
            }
            this.$emit('set-quantity', this.quantity);
            this.$v.total.$reset();
        },
        price() {
            if (this.isModal) {
                this.calculateAccountsFormData();
            }
            this.$v.price.$reset();
        },
        async activeSide() {
            if (this.isModal) {
                this.calculateAccountsFormData();
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.mainContainer {
    height: calc(100% - 50px);
}
.row {
    display: flex;
    flex-direction: row;
}
.alignCenter {
    align-items: center;
}
.rowGap {
    row-gap: var(--m-xxl);
}
.firstColumnPadding {
    padding: 0 var(--m-xs) 0 var(--m-m);
}
.secondColumnPadding {
    padding: 0 var(--m-m) 0 var(--m-xs);
}
.paddingX {
    padding: 0 var(--m-m);
}
.paddingY {
    padding: var(--m-m) 0;
}
.borderRight {
    border-right: 1px solid var(--cl-gray-light);
}
.accountsListHeaderText {
    font-weight: var(--fw-extrabold);
    font-size: var(--fs-m);
    line-height: var(--fs-m);
    color: var(--cl-black);
    padding: 0 var(--m-m);
}
.prS {
    padding-right: var(--m-s);
}
.justifyContentBetween {
    justify-content: space-between;
}
.justifyContentEnd {
    justify-content: flex-end;
}
.w100 {
    width: 100%;
}
.h100 {
    height: 100%;
}
.pbM {
    padding-bottom: var(--m-m);
}
.w25 {
    width: 25%;
}
.w20 {
    width: 20%;
}
.w30 {
    width: 30%;
}
.w50 {
    width: 50%;
}
.w33 {
    width: 33%;
}
.w66 {
    width: 66%;
}
.column {
    display: flex;
    flex-direction: column;
}
.leverageContainer {
    display: flex;
    margin-left: var(--m-m);
    margin-bottom: var(--m-m);
}
.mainAccountsGrid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    width: 100%;
    grid-row-gap: var(--m-l);
}
.additionalGridCell {
    grid-column-start: 1;
    grid-column-end: 4;
    padding: var(--m-xs);
    border: 1px solid var(--cl-gray-light);
    border-radius: 4px;
}
.additionalGridCellBackground {
    background-color: var(--cl-violet-light);
}
.balancesGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.multiaccountButton {
    margin-right: var(--m-xs);
    padding: 0 var(--m-s);
}
.modalContainer {
    padding: 0 var(--m-xl);
    border-top: 1px solid var(--cl-gray-light);
    position: relative;
    left: -20px;
    width: calc(100% + 36px) !important;
}
.mtMedium {
    margin-top: var(--m-m);
}
.mtExtraLarge {
    margin-top: var(--m-xxxl);
}
.buttonsContainer {
    padding: 0 var(--m-m);
    margin-bottom: var(--m-m);
}
.mlSmall {
    margin-left: var(--m-xs);
}
.mbSmall {
    margin-bottom: var(--m-xs);
}
.mrSmall {
    margin-right: var(--m-xs);
}
.accountsListContainer {
    padding: var(--m-l) var(--m-m);
    align-items: center;
}
.toggleBarsContainer {
    justify-content: flex-end;
    align-items: center;
}
.cancelButton {
    border: 1px solid var(--cl-violet);
    margin-right: var(--m-s);
    width: 25%;
}
.prXxxl {
    padding-right: var(--m-xxxl);
}
.editText {
    font-style: normal;
    font-weight: var(--fw-semibold);
    font-size: var(--fs-m);
    line-height: var(--fs-m);
    color: var(--cl-gray);
}
.tag {
    display: flex;
    align-items: center;
    background-color: var(--cl-gray-light);
    padding: var(--m-xs) var(--m-s);
    border-radius: 100px;
    column-gap: var(--m-s);
    width: max-content;
    cursor: pointer;
    font-size: var(--fs-s);
    font-weight: var(--fw-semibold);
}
.smallGap {
    column-gap: var(--m-s);
}

.confirmMarginOrderModal {
    display: flex;
    flex-direction: column;
    row-gap: var(--m-m);

    & .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: var(--m-m);
        margin: var(--m-m) var(--m-m) 0 var(--m-m);
        position: relative;

        &::before {
            position: absolute;
            content: "";
            bottom: 0;
            left: calc(-1 * var(--m-m));
            width: calc(100% + (2 * var(--m-m)));
            height: 2px;
            background-color: var(--cl-gray-light);
        }

        & .buySellText {
            font-size: var(--fs-xl);
            font-weight: var(--fw-bold);
            color: var(--cl-black);
        }

        & .headerInfoText {
            font-size: var(--fs-m);
            font-weight: var(--fw-semibold);

            &.green {
                color: var(--cl-green);
            }

            &.red {
                color: var(--cl-red);
            }
        }
    }

    & .infoBlock {
        display: flex;
        column-gap: var(--m-m);
        padding: var(--m-m);
        background-color: var(--cl-white-background);
        border-radius: 4px;
        margin: 0 var(--m-m) 0 var(--m-m);

        & .infoText {
            font-size: var(--fs-m);
            font-weight: var(--fw-semibold);
            color: var(--cl-gray);
        }

        & .infoIcon {
            width: 24px;
            height: 24px;
            color: var(--cl-violet);
        }
    }

    & .dataBlock {
        display: flex;
        flex-direction: column;
        row-gap: var(--m-m);
        padding: var(--m-m);
        background-color: var(--cl-violet-light);
        border-radius: 4px;
        margin: 0 var(--m-m) 0 var(--m-m);

        & .dataHeader {
            display: flex;
            flex-direction: column;
            padding-bottom: var(--m-m);
            position: relative;

            &::before {
                position: absolute;
                content: "";
                bottom: 0;
                left: calc(-1 * var(--m-m));
                width: calc(100% + (2 * var(--m-m)));
                height: 2px;
                background-color: var(--cl-gray-light);
            }

            & .row {
                display: flex;
                align-items: center;
                column-gap: var(--m-s);
            }

            & .subRow {
                display: flex;
                align-items: center;
                column-gap: var(--m-xs);
            }

            & .dataHeaderText {
                font-size: var(--fs-s);
                font-weight: var(--fw-bold);
                color: var(--cl-violet);
            }

            & .placementAssetText {
                font-size: var(--fs-m);
                font-weight: var(--fw-semibold);
                color: var(--cl-black);
            }

            & .secondCryptoIcon {
                margin-left: -8px;
            }
        }

        & .dataRow {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.start {
                align-items: flex-start;
            }

            & .dataFieldName {
                font-size: var(--fs-s);
                font-weight: var(--fw-bold);
                color: var(--cl-violet);
                text-transform: uppercase;
            }

            & .dataFieldValue {
                font-size: var(--fs-m);
                font-weight: var(--fw-semibold);
                color: var(--cl-black);
            }

            & .dataFieldValueQuotation {
                font-size: var(--fs-s);
                font-weight: var(--fw-semibold);
                color: var(--cl-gray);
            }

            & .dataFieldValueAsset {
                font-size: var(--fs-xxs);
            }

            & .subCol {
                display: flex;
                flex-direction: column;
                row-gap: var(--m-s);
                align-items: flex-end;
            }
        }
    }

    & .buttonsRow {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--m-m);
        margin: 0 var(--m-m) var(--m-m) var(--m-m);
    }
}
</style>

<style>
.crypto-limit-form {
    & .vm--modal {
        border-radius: 15px !important;
        background: var(--cl-white) !important;
        max-width: 300px !important;
    }
}
</style>
