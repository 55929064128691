
import Vue from 'vue';
import { maxValue, minValue, required } from 'vuelidate/dist/validators.min';

import theme from 'Theme';
import Button from 'Control/Button.vue';
import Dropdown from 'Control/Dropdown.vue';
import Icon from 'UI/Icon.vue';
import { FuturesOrderSides, OrderActiveFieldValues as fieldPriorities, OrdersTypes } from 'Models/trading';
import numberFormater from 'Mixins/numberFormater';
import Placement from 'Entities/publicPresenter/Placement';
import orderValue from 'Mixins/orderValue';
import { getAvailableLeverages, getPositions, placeOrder, QUANTITY_TYPES, SUPPORTED_DIRECTIONS } from 'Store/v2/Futures';
import FuturesPosition from 'Entities/privatePresenter/FuturesPosition';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import OrderValueBlock from 'Modules/Trading/components/OrderValueBlock.vue';
import MoneyInput from 'Control/MoneyInput.vue';
import SwitchControl from 'Control/Switch.vue';
import TakeProfitStopLossModal from 'Modules/OrdersHistory/components/TakeProfitStopLossModal.vue';
import FuturesOrderPresenter from 'Entities/privatePresenter/FuturesOrderPresenter';
import {
    IPlaceOrderPayload,
    SET_POSITION,
    SET_STOP_LOSS_ORDER,
    SET_TAKE_PROFIT_ORDER,
} from 'Store/v2/TakeProfitStopLossModal';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import FuturesApi from 'Apis/Futures';
import OrderCostRequest from 'Entities/futuresOrderRegistrar/OrderCostRequest';
import TradingApi from 'Apis/Trading';
import FuturesOrdersRequest from 'Entities/privatePresenter/FuturesOrdersRequest';

import SelectLeverage from './SelectLeverage.vue';

interface Data {
    priorityField: string;
    formData: { price: number, total: number, quantity: number, stopPrice: number };
    quantityButtons: any[];
    totalButtons: any[];
    priceButtons: any[];
    showTooltip: boolean;
    direction: string;
    selectedLeverage: null | number;
    isTpSlEnabled: boolean;
    tpSlPayload: IPlaceOrderPayload | null;
    hasTpSlQuantityError: boolean;
    hasTpSlPriceError: boolean;
    activeTpOrder: FuturesOrderPresenter | undefined;
    activeSlOrder: FuturesOrderPresenter | undefined;
}

interface Methods {
    setQuantityMinValue: () => void;
    setQuantityPartOfFree: (data: number) => void;
    setTotalMinValue: () => void;
    setTotalPartOfFree: (data: number) => void;
    autoSetPrice: (data: string) => void;
    autoSetStopPrice: (data: string) => void;
    setQuantity: (data: number) => void;
    setPrice: (data: number) => void;
    setStopPrice: (data: number) => void;
    setTotal: (data: number) => void;
    placeOrder: () => void;
    clearForm: () => void;
    switchDirection: () => void;
    changeLeverage: (data: number) => void;
    updateLeverage: () => void;
    onTpSlConfirm: (data: IPlaceOrderPayload) => void;
    onTpSlCancel: () => void;
    editTpSl: () => void;
    openModal: () => void;
    onTpSlChange: (e: HTMLInputElement) => void;
    validateOrderCost: () => Promise<boolean>;
    getActiveTpOrder: () => void;
    getActiveSlOrder: () => void;
}

interface Computed {
    tpSlUpdatesCounter: number;
    totalErrorText: string;
    quantityErrorText: string;
    priceErrorText: string;
    stopPriceErrorText: string;
    isKycVerified: boolean;
    placementId: string;
    quotationAssetCharacter: string;
    totalBalanceFree: number;
    baseAssetSymbol: string;
    quoteAssetSymbol: string;
    activeTerminalAssetPairPrecisionAmount: number;
    activeTerminalAssetPairPrecisionPrice: number;
    activeSide: any;
    commissionAsset: string;
    quantityRestriction: any;
    priceRestriction: any;
    totalRestriction: any;
    price: number;
    stopPrice: number;
    quantity: number;
    total: number;
    askPrice: number;
    bidPrice: number;
    lastMarketPrice: number | undefined;
    totalBalance: any;
    quantityBalance: any;
    quantityPrecision: number;
    pricePrecision: number;
    isQuantityPriority: boolean;
    isQuantityButtonsDisabled: boolean;
    quantityMaxValue: number;
    quantityMinValue: number;
    disabledQuantityButtonsIndexes: number[];
    isTotalButtonsDisabled: boolean;
    totalMaxValue: number;
    disabledTotalButtonsIndexes: number[];
    totalMinValue: number;
    currentUser: InternalUserResponse | undefined;
    currentPlacement: Placement;
    newCommission: number;
    quotedCommission: number;
    quantityType: string;
    contractPrice: number;
    activeAccountId: string | undefined;
    positions: FuturesPosition[];
    currentAssetPairShortPosition: FuturesPosition | undefined;
    currentAssetPairLongPosition: FuturesPosition | undefined;
    isShortAvailable: boolean;
    isLongAvailable: boolean;
    availableLeverages: number[];
    availableQuantityValue: number | boolean;
    availableTotalValue: number | boolean;
    showEditTpSl: boolean;
}

export default Vue.extend<Data, Methods, Computed, any>({
    props: {
        orderType: {
            type: String,
            required: true,
        },
        placement: {
            type: String,
            required: true,
        },
        pair: {
            type: String,
            required: true,
        },
        storeData: {
            type: undefined,
            required: true,
        },
        currentExpiries: {
            type: String,
            required: true,
        },
        uniqueId: {
            type: String,
            required: true,
        },
        presetedLeverage: {
            type: Number,
            default: 1,
        },
        presetedQuantity: {
            type: Number,
            default: 0,
        },
    },
    mixins: [numberFormater, orderValue],
    components: {
        TakeProfitStopLossModal,
        SwitchControl,
        Icon,
        SelectLeverage,
        OrderValueBlock,
        Button,
        Dropdown,
        MoneyInput,
    },
    data() {
        return {
            OrdersTypes,
            FuturesOrderSides,
            QUANTITY_TYPES,
            SUPPORTED_DIRECTIONS,
            direction: SUPPORTED_DIRECTIONS.long,
            priorityField: fieldPriorities.QUANTITY,
            selectedLeverage: 1,
            theme,
            isTpSlEnabled: false,
            tpSlPayload: null,
            hasTpSlQuantityError: false,
            hasTpSlPriceError: false,
            formData: {
                price: 0,
                stopPrice: 0,
                total: 0,
                quantity: 0,
            },
            activeTpOrder: undefined,
            activeSlOrder: undefined,
            showTooltip: true,

            quantityButtons: [
                {
                    title: 'Min',
                    callback: () => (this as any).setQuantityMinValue(),
                },
                {
                    title: '25%',
                    callback: () => (this as any).setQuantityPartOfFree(0.25),
                },
                {
                    title: '50%',
                    callback: () => (this as any).setQuantityPartOfFree(0.5),
                },
                {
                    title: '75%',
                    callback: () => (this as any).setQuantityPartOfFree(0.75),
                },
                {
                    title: 'Max',
                    callback: () => (this as any).setQuantityPartOfFree(1),
                },
            ],
            totalButtons: [
                {
                    title: 'Min',
                    callback: () => (this as any).setTotalMinValue(),
                },
                {
                    title: '25%',
                    callback: () => (this as any).setTotalPartOfFree(0.25),
                },
                {
                    title: '50%',
                    callback: () => (this as any).setTotalPartOfFree(0.5),
                },
                {
                    title: '75%',
                    callback: () => (this as any).setTotalPartOfFree(0.75),
                },
                {
                    title: 'Max',
                    callback: () => (this as any).setTotalPartOfFree(1),
                },
            ],
            priceButtons: [
                {
                    title: 'Bid',
                    callback: () => {
                        (this as any).autoSetPrice('BID');
                    },
                },
                {
                    title: 'Market',
                    isLarge: true,
                    callback: () => {
                        (this as any).autoSetPrice('MARKET');
                    },
                },
                {
                    title: 'Ask',
                    callback: () => {
                        (this as any).autoSetPrice('ASK');
                    },
                },
            ],
            stopPriceButtons: [
                {
                    title: 'Bid',
                    callback: () => {
                        (this as any).autoSetStopPrice('BID');
                    },
                },
                {
                    title: 'Market',
                    isLarge: true,
                    callback: () => {
                        (this as any).autoSetStopPrice('MARKET');
                    },
                },
                {
                    title: 'Ask',
                    callback: () => {
                        (this as any).autoSetStopPrice('ASK');
                    },
                },
            ],
        };
    },
    validations() {
        return {
            quantity: {
                required,
                minValue: minValue(this.quantityMinValue),
                maxValue: maxValue(this.activeSide.value === FuturesOrderSides.OPEN.value ? Infinity : this.quantityMaxValue),
            },
            total: {
                required,
                minValue: minValue(this.totalMinValue),
                maxValue: maxValue(this.activeSide.value === FuturesOrderSides.OPEN.value ? this.totalMaxValue : Infinity),
            },
            price: {
                required,
                minValue: minValue(this.priceRestriction.minValue),
                maxValue: maxValue(this.priceRestriction.maxValue),
            },
            stopPrice: {
                required,
                minValue: minValue(this.priceRestriction.minValue),
                maxValue: maxValue(this.priceRestriction.maxValue),
            },
        };
    },
    computed: {
        tpSlUpdatesCounter() {
            return this.$store.state.TakeProfitStopLossModal.tpSlUpdatesCounter;
        },
        totalErrorText() {
            if (!this.$v.total.minValue) {
                return `Total value must be greater than ${this.totalMinValue.noExponents()}`;
            }
            if (!this.$v.total.maxValue) {
                return `Total value must be lower than ${this.totalMaxValue.noExponents()}`;
            }
            return '';
        },
        quantityErrorText() {
            if (!this.$v.quantity.minValue) {
                return `Quantity value must be greater than ${this.quantityMinValue.noExponents()}`;
            }
            if (!this.$v.quantity.maxValue) {
                return `Quantity value must be lower than ${this.quantityMaxValue.noExponents()}`;
            }
            return '';
        },
        priceErrorText() {
            if (!this.$v.price.minValue) {
                return `Price value must be greater than ${this.priceRestriction.minValue.noExponents()}`;
            }
            if (!this.$v.price.maxValue) {
                return `Price value must be lower than ${this.priceRestriction.maxValue.noExponents()}`;
            }
            return '';
        },
        stopPriceErrorText() {
            if (!this.$v.stopPrice.minValue) {
                return `Stop price value must be greater than ${this.priceRestriction.minValue.noExponents()}`;
            }
            if (!this.$v.stopPrice.maxValue) {
                return `Stop price value must be lower than ${this.priceRestriction.maxValue.noExponents()}`;
            }
            return '';
        },
        showEditTpSl() {
            return !!(this.tpSlPayload?.takeProfitTriggerPrice || this.activeTpOrder || this.tpSlPayload?.stopLossTriggerPrice || this.activeSlOrder);
        },
        quotationAssetCharacter() {
            return this.$store.getters['Assets/GET_QUOTATION_ASSET_CHARACTER'];
        },
        placementId() {
            return this.$store.getters['Placements/getPlacementIdByName'](this.placement);
        },
        totalBalanceFree() {
            return this.storeData.totalBalanceFree;
        },
        baseAssetSymbol() {
            return this.pair.split('/')[0];
        },
        quoteAssetSymbol() {
            return this.pair.split('/')[1];
        },
        activeTerminalAssetPairPrecisionAmount() {
            return this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair)?.placementPrecisionQuantity ?? 8;
        },
        activeTerminalAssetPairPrecisionPrice() {
            return this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair)?.placementPrecisionPrice ?? 8;
        },
        activeSide() {
            return this.storeData.activeSide;
        },
        commissionAsset() {
            return this.storeData.commissionAsset;
        },
        quantityRestriction() {
            return this.storeData.quantityRestriction;
        },
        priceRestriction() {
            return this.storeData.priceRestriction;
        },
        totalRestriction() {
            return this.storeData.totalRestriction;
        },

        price: {
            get() {
                return this.formData.price;
            },
            set(value) {
                this.formData.price = value;
            },
        },
        stopPrice: {
            get() {
                return this.formData.stopPrice;
            },
            set(value) {
                this.formData.stopPrice = value;
            },
        },
        quantity: {
            get() {
                // eslint-disable-next-line no-nested-ternary
                const result = (this as any).truncateNumber(Number(this.isQuantityPriority ? this.formData.quantity : this.quantityType === QUANTITY_TYPES.base ? this.total / this.price : Math.floor((this.total / this.price) / this.contractPrice)) || 0, this.quantityPrecision);
                return result === Infinity ? 0 : result;
            },
            set(quantity) {
                this.formData.quantity = (this as any).truncateNumber(Number(quantity) || 0, this.quantityPrecision);
                this.priorityField = fieldPriorities.QUANTITY;
            },
        },
        total: {
            get() {
                // eslint-disable-next-line no-nested-ternary
                return (this as any).truncateNumber(Number(this.isQuantityPriority ? this.quantityType === QUANTITY_TYPES.base ? this.quantity * this.price : (this.quantity * this.contractPrice) * this.price : this.formData.total) || 0, this.pricePrecision);
            },
            set(total) {
                this.formData.total = (this as any).truncateNumber(Number(total) || 0, this.pricePrecision);
                this.priorityField = fieldPriorities.TOTAL;
            },
        },
        bidPrice() {
            return this.storeData.bidPrice;
        },
        askPrice() {
            return this.storeData.askPrice;
        },
        lastMarketPrice() {
            return this.storeData.marketPrice;
        },
        quantityBalance() {
            return this.$store.state.Balances.balances?.find(
                (b) => b.assetSymbol === this.baseAssetSymbol && b.placementId.toString() === this.placementId.toString(),
            );
        },
        totalBalance() {
            return this.$store.state.Balances.balances?.find(
                (b) => b.assetSymbol === this.quoteAssetSymbol && b.placementId.toString() === this.placementId.toString(),
            );
        },
        quantityPrecision() {
            if (this.quantityType === QUANTITY_TYPES.contract) {
                return 0;
            }
            return Math.min(
                this.activeTerminalAssetPairPrecisionAmount,
                this.quantityRestriction.stepSize ? this.quantityRestriction.stepSize.getPrecision() : Infinity,
            );
        },
        pricePrecision() {
            return Math.min(
                this.activeTerminalAssetPairPrecisionPrice,
                this.priceRestriction.stepSize ? this.priceRestriction.stepSize.getPrecision() : Infinity,
            );
        },
        isQuantityPriority() {
            return this.priorityField === fieldPriorities.QUANTITY;
        },
        isQuantityButtonsDisabled() {
            if (this.activeSide.value === FuturesOrderSides.OPEN.value) {
                return this.totalBalance ? this.totalBalance.free === 0 : true;
            }
            if (this.activeSide.value === FuturesOrderSides.CLOSE.value) {
                if (this.direction === SUPPORTED_DIRECTIONS.long) {
                    if (!this.isLongAvailable) {
                        return true;
                    }
                    return this.currentAssetPairLongPosition!.quantity === 0;
                }
                if (this.direction === SUPPORTED_DIRECTIONS.short) {
                    if (!this.isShortAvailable) {
                        return true;
                    }
                    return this.currentAssetPairShortPosition!.quantity === 0;
                }
            }
            return true;
        },
        quantityMaxValue() {
            if (this.activeSide.value === FuturesOrderSides.CLOSE.value) {
                if (this.direction === SUPPORTED_DIRECTIONS.long) {
                    if (!this.isLongAvailable) {
                        return 0;
                    }
                    return this.currentAssetPairLongPosition!.quantity;
                }
                if (this.direction === SUPPORTED_DIRECTIONS.short) {
                    if (!this.isShortAvailable) {
                        return 0;
                    }
                    return this.currentAssetPairShortPosition!.quantity;
                }
            }
            if (this.activeSide.value === FuturesOrderSides.OPEN.value) {
                const quoteAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.quoteAssetSymbol);
                const baseAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.baseAssetSymbol);
                if (!quoteAsset || !baseAsset) {
                    return 0;
                }
                if (this.quantityType === QUANTITY_TYPES.base) {
                    return (this.totalBalanceFree * (this.$store.state.Assets.quotations.get(quoteAsset.symbol)?.USD ?? 0)) / (this.$store.state.Assets.quotations.get(baseAsset.symbol)?.USD ?? 0);
                }
                if (this.quantityType === QUANTITY_TYPES.contract) {
                    return Math.floor(((this.totalBalanceFree * (this.$store.state.Assets.quotations.get(quoteAsset.symbol)?.USD ?? 0)) / (this.$store.state.Assets.quotations.get(baseAsset.symbol)?.USD ?? 0)) / this.contractPrice);
                }
            }
            return 0;
        },
        quantityMinValue() {
            const baseAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.baseAssetSymbol);
            if (baseAsset) {
                const baseAssetRate = Number((this.$store.state.Assets.quotations.get(baseAsset.symbol)?.USD ?? 0));
                if (baseAssetRate && baseAssetRate !== 0) {
                    return this.quantityType === QUANTITY_TYPES.base ? (25 / baseAssetRate).floor(this.quantityPrecision) : (25 / baseAssetRate / this.contractPrice).floor(this.quantityPrecision);
                }
                return 0;
            }
            return 0;
        },
        disabledQuantityButtonsIndexes() {
            const result: number[] = [];
            let balance;
            if (this.activeSide.value === FuturesOrderSides.OPEN.value) {
                balance = this.totalBalanceFree * Number(this.selectedLeverage);
            } else if (this.activeSide.value === FuturesOrderSides.CLOSE.value) {
                if (this.direction === SUPPORTED_DIRECTIONS.long) {
                    if (this.isLongAvailable) {
                        balance = this.currentAssetPairLongPosition!.quantity;
                    }
                } else if (this.direction === SUPPORTED_DIRECTIONS.short) {
                    if (this.isShortAvailable) {
                        balance = this.currentAssetPairShortPosition!.quantity;
                    }
                }
            }
            if (!balance) {
                return [1, 2, 3, 4];
            }
            const minValue = this.activeSide.value === FuturesOrderSides.OPEN.value ? this.totalMinValue : this.quantityMinValue;
            if (balance * 0.25 < minValue) {
                result.push(1);
            }
            if (balance * 0.5 < minValue) {
                result.push(2);
            }
            if (balance * 0.75 < minValue) {
                result.push(3);
            }
            if (balance < minValue) {
                result.push(4);
            }
            return result;
        },
        isTotalButtonsDisabled() {
            return this.isQuantityButtonsDisabled;
        },
        totalMaxValue() {
            if (this.activeSide.value === FuturesOrderSides.OPEN.value) {
                return Number(this.totalBalanceFree) * Number(this.selectedLeverage);
            }
            if (this.activeSide.value === FuturesOrderSides.CLOSE.value) {
                if (this.direction === SUPPORTED_DIRECTIONS.long) {
                    if (!this.isLongAvailable) {
                        return 0;
                    }
                    const quoteAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.quoteAssetSymbol);
                    if (quoteAsset) {
                        const quoteAssetRate = (this.$store.state.Assets.quotations.get(quoteAsset.symbol)?.USD ?? 0);
                        return this.currentAssetPairLongPosition!.quotedQuantity / quoteAssetRate;
                    }
                    return 0;
                }
                if (this.direction === SUPPORTED_DIRECTIONS.short) {
                    if (!this.isShortAvailable) {
                        return 0;
                    }
                    const quoteAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.quoteAssetSymbol);
                    if (quoteAsset) {
                        const quoteAssetRate = (this.$store.state.Assets.quotations.get(quoteAsset.symbol)?.USD ?? 0);
                        return this.currentAssetPairShortPosition!.quotedQuantity / quoteAssetRate;
                    }
                    return 0;
                }
            }
            return 0;
        },
        totalMinValue() {
            const quoteAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.quoteAssetSymbol);
            if (quoteAsset) {
                const quoteAssetRate = (this.$store.state.Assets.quotations.get(quoteAsset.symbol)?.USD ?? 0);
                if (quoteAssetRate !== 0) {
                    return (25 / quoteAssetRate).floor(this.pricePrecision);
                }
                return 0;
            }
            return 0;
        },
        disabledTotalButtonsIndexes() {
            const result: number[] = [];
            let balance;
            if (this.activeSide.value === FuturesOrderSides.OPEN.value) {
                balance = this.totalBalanceFree * Number(this.selectedLeverage);
            } else if (this.activeSide.value === FuturesOrderSides.CLOSE.value) {
                if (this.direction === SUPPORTED_DIRECTIONS.long) {
                    if (this.isLongAvailable) {
                        balance = this.currentAssetPairLongPosition!.quantity;
                    }
                } else if (this.direction === SUPPORTED_DIRECTIONS.short) {
                    if (this.isShortAvailable) {
                        balance = this.currentAssetPairShortPosition!.quantity;
                    }
                }
            }
            if (!balance) {
                return [1, 2, 3, 4];
            }
            const minValue = this.activeSide.value === FuturesOrderSides.OPEN.value ? this.totalMinValue : this.quantityMinValue;
            if (balance * 0.25 < minValue) {
                result.push(1);
            }
            if (balance * 0.5 < minValue) {
                result.push(2);
            }
            if (balance * 0.75 < minValue) {
                result.push(3);
            }
            if (balance < minValue) {
                result.push(4);
            }
            return result;
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        currentPlacement() {
            return this.$store.state.Placements.placements.find((p) => p.id === this.placementId);
        },
        newCommission() {
            if (this.currentPlacement) {
                if (this.currentExpiries === 'GTC') {
                    return this.activeSide.value === 'SELL' ? this.total * Number(this.currentPlacement.commissionMaker) * 0.01 : this.quantity * Number(this.currentPlacement.commissionMaker) * 0.01;
                }
                return this.activeSide.value === 'SELL' ? this.total * Number(this.currentPlacement.commissionTaker) * 0.01 : this.quantity * Number(this.currentPlacement.commissionTaker) * 0.01;
            }
            return 0;
        },
        quotedCommission() {
            if (this.currentPlacement) {
                if (this.currentExpiries === 'GTC') {
                    return this.orderValue * Number(this.currentPlacement.commissionMaker) * 0.01;
                }
                return this.orderValue * Number(this.currentPlacement.commissionTaker) * 0.01;
            }
            return 0;
        },
        quantityType() {
            const placementOrderTypes = (this.currentPlacement as any).orderQuantityTypes;
            if (!placementOrderTypes) {
                return 'base';
            }
            const expiry = this.currentExpiries;
            return (placementOrderTypes[`LIMIT_${expiry}`])[this.activeSide.value === 'OPEN' ? 'BUY' : 'SELL'];
        },
        contractPrice() {
            const assetPair = this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair);
            if (assetPair) {
                return Number(assetPair.contractPrice);
            }
            return 0;
        },
        activeAccountId() {
            return this.$store.getters['Accounts/activeAccountID'];
        },
        positions() {
            return this.$store.state.Futures.positions;
        },
        currentAssetPairShortPosition() {
            return this.positions.find(({ side, contractSymbol: symbol }) => side === 'SHORT' && symbol === `${this.baseAssetSymbol}/${this.quoteAssetSymbol}`);
        },
        currentAssetPairLongPosition() {
            return this.positions.find(({ side, contractSymbol: symbol }) => side === 'LONG' && symbol === `${this.baseAssetSymbol}/${this.quoteAssetSymbol}`);
        },
        isLongAvailable() {
            return this.currentAssetPairLongPosition !== undefined || this.activeSide.value === FuturesOrderSides.OPEN.value;
        },
        isShortAvailable() {
            return this.currentAssetPairShortPosition !== undefined || this.activeSide.value === FuturesOrderSides.OPEN.value;
        },
        availableLeverages() {
            const leverages = this.$store.state.AssetPairs.workspaceSpotAssetPairs.get(this.placement)?.get(this.pair)?.placementLeverages;
            if (!leverages) {
                return [1];
            }
            return leverages.map((l) => Number(l.level)).sort((a, b) => a - b);
        },
        availableQuantityValue() {
            if (this.activeSide.value === this.FuturesOrderSides.OPEN.value) {
                return false;
            }
            if (this.direction === SUPPORTED_DIRECTIONS.long) {
                return this.currentAssetPairLongPosition ? this.currentAssetPairLongPosition.quantity : 0;
            }
            if (this.direction === SUPPORTED_DIRECTIONS.short) {
                return this.currentAssetPairShortPosition ? this.currentAssetPairShortPosition.quantity : 0;
            }
            return false;
        },
        availableTotalValue() {
            if (this.activeSide.value === this.FuturesOrderSides.CLOSE.value) {
                return false;
            }
            return this.totalBalanceFree;
        },
    },
    methods: {
        async getActiveTpOrder() {
            try {
                if (!this.activeAccountId) {
                    this.activeTpOrder = undefined;
                    return;
                }

                const { data: orders } = await TradingApi.privateFuturesGetOrders(new FuturesOrdersRequest({
                    statuses: ['REGISTERED', 'PLACED', 'PARTIALY_FILLED'],
                    placementName: this.currentPlacement?.name,
                    positionSide: this.direction as 'LONG' | 'SHORT',
                    spotAssetPairSymbol: this.pair,
                    accountId: this.activeAccountId,
                    type: 'TAKE_PROFIT',
                }));
                if (orders.length > 0) {
                    [this.activeTpOrder] = orders;
                } else {
                    this.activeTpOrder = undefined;
                }
            } catch {
                this.activeTpOrder = undefined;
            }
        },
        async getActiveSlOrder() {
            try {
                if (!this.activeAccountId) {
                    this.activeSlOrder = undefined;
                    return;
                }

                const { data: orders } = await TradingApi.privateFuturesGetOrders(new FuturesOrdersRequest({
                    statuses: ['REGISTERED', 'PLACED', 'PARTIALY_FILLED'],
                    placementName: this.currentPlacement?.name,
                    positionSide: this.direction as 'LONG' | 'SHORT',
                    spotAssetPairSymbol: this.pair,
                    accountId: this.activeAccountId,
                    type: 'STOP_LOSS',
                }));
                if (orders.length > 0) {
                    [this.activeSlOrder] = orders;
                } else {
                    this.activeSlOrder = undefined;
                }
            } catch {
                this.activeSlOrder = undefined;
            }
        },
        async validateOrderCost() {
            const { data: cost } = await FuturesApi.privateFuturesOrderCost(new OrderCostRequest({
                accountId: this.activeAccountId ?? '',
                bestAsk: String(this.askPrice),
                bestBid: String(this.bidPrice),
                contractSymbol: this.pair,
                leverage: Number(this.selectedLeverage),
                placementTag: this.$store.state.Placements.placementNamesToPlacementTags.get(this.placement.toUpperCase()),
                positionSide: this.direction.toUpperCase(),
                price: String(this.price),
                quantity: String(this.quantity),
                side: 'BUY',
            }));
            return (cost.cost ?? 0) <= this.totalBalanceFree;
        },
        onTpSlChange(e: HTMLInputElement) {
            if (!this.isTpSlEnabled) {
                if (!this.quantity) {
                    this.hasTpSlQuantityError = true;
                }
                if (!this.price) {
                    this.hasTpSlPriceError = true;
                }
                if (!this.price || !this.quantity) {
                    e.checked = false;
                    return;
                }
            }
            this.isTpSlEnabled = !this.isTpSlEnabled;
            e.checked = this.isTpSlEnabled;
            if (this.isTpSlEnabled) {
                this.openModal();
            }
        },
        async editTpSl() {
            if (this.isTpSlEnabled || this.activeTpOrder || this.activeSlOrder) {
                await this.openModal();
                if (this.isTpSlEnabled) {
                    setTimeout(() => {
                        (this.$refs.TakeProfitStopLossModal as any).presetStartValues(this.tpSlPayload);
                    }, 200);
                }
                if (this.activeTpOrder) {
                    setTimeout(() => {
                        (this.$refs.TakeProfitStopLossModal as any).presetStartValues({
                            orderType: this.activeTpOrder?.price ? 'Limit' : 'Market',
                            takeProfitTriggerPrice: this.activeTpOrder?.triggerPrice,
                            takeProfitPrice: this.activeTpOrder?.price ?? undefined,
                        });
                    }, 200);
                }
                if (this.activeSlOrder) {
                    setTimeout(() => {
                        (this.$refs.TakeProfitStopLossModal as any).presetStartValues({
                            orderType: this.activeSlOrder?.price ? 'Limit' : 'Market',
                            takeProfitTriggerPrice: this.activeSlOrder?.triggerPrice,
                            takeProfitPrice: this.activeSlOrder?.price ?? undefined,
                        });
                    }, 200);
                }
            }
        },
        async openModal() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));

                if (this.direction === SUPPORTED_DIRECTIONS.long) {
                    if (this.currentAssetPairLongPosition) {
                        this.$store.commit(SET_POSITION(this.currentAssetPairLongPosition));
                    } else {
                        this.$store.commit(SET_POSITION(new FuturesPosition({
                            accountId: this.activeAccountId ?? '',
                            balanceId: 'fake',
                            contractSymbol: this.pair,
                            entryPrice: String(this.price),
                            leverage: String(this.selectedLeverage),
                            liquidationPrice: '0',
                            margin: String(this.total / (this.selectedLeverage ?? 1)),
                            markPrice: String(this.lastMarketPrice),
                            placementName: this.currentPlacement.name,
                            profit: '0',
                            profitPercent: 0,
                            quantity: String(this.quantity),
                            quantityUnit: 'BASE',
                            quotationAssetSymbol: 'USD',
                            quoteQuantity: String(this.total),
                            quotedQuantity: '0',
                            quotedUnrealizedProfit: '0',
                            side: 'LONG',
                            unrealizedProfit: '0',
                            updatedAt: Date.now().toString(),
                        })));
                    }
                } else if (this.currentAssetPairShortPosition) {
                    this.$store.commit(SET_POSITION(this.currentAssetPairShortPosition));
                } else {
                    this.$store.commit(SET_POSITION(new FuturesPosition({
                        accountId: this.activeAccountId ?? '',
                        balanceId: 'fake',
                        contractSymbol: this.pair,
                        entryPrice: String(this.price),
                        leverage: String(this.selectedLeverage),
                        liquidationPrice: '0',
                        margin: String(this.total / (this.selectedLeverage ?? 1)),
                        markPrice: String(this.lastMarketPrice),
                        placementName: this.currentPlacement.name,
                        profit: '0',
                        profitPercent: 0,
                        quantity: String(this.quantity),
                        quantityUnit: 'BASE',
                        quotationAssetSymbol: 'USD',
                        quoteQuantity: String(this.total),
                        quotedQuantity: '0',
                        quotedUnrealizedProfit: '0',
                        side: 'SHORT',
                        unrealizedProfit: '0',
                        updatedAt: Date.now().toString(),
                    })));
                }

                if (this.activeSlOrder) {
                    this.$store.commit(SET_STOP_LOSS_ORDER(this.activeSlOrder));
                } else {
                    this.$store.commit(SET_STOP_LOSS_ORDER(null));
                }
                if (this.activeTpOrder) {
                    this.$store.commit(SET_TAKE_PROFIT_ORDER(this.activeTpOrder));
                } else {
                    this.$store.commit(SET_TAKE_PROFIT_ORDER(null));
                }

                this.$modal.show(`tpsl${this.uniqueId}`);
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        onTpSlConfirm(payload) {
            this.tpSlPayload = payload;
            if (this.tpSlPayload) {
                this.isTpSlEnabled = true;
            }
        },
        onTpSlCancel() {
            if (this.activeTpOrder || this.activeSlOrder) {
                return;
            }
            this.isTpSlEnabled = false;
        },
        setQuantityMinValue() {
            const baseAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.baseAssetSymbol);
            if (baseAsset) {
                const baseAssetRate = (this.$store.state.Assets.quotations.get(baseAsset.symbol)?.USD ?? 0);
                if (baseAssetRate !== 0) {
                    this.quantity = this.quantityType === QUANTITY_TYPES.base ? (25 / baseAssetRate) : Math.floor((25 / baseAssetRate) / this.contractPrice);
                    if (this.total === 0) {
                        this.total = 1 / 10 ** this.pricePrecision;
                    }
                    if (this.quantity === 0) {
                        this.quantity = 1 / 10 ** this.quantityPrecision;
                    }
                } else {
                    this.quantity = 0;
                }
            } else {
                this.quantity = 0;
            }
            (this.$refs.TotalInput as any).cleanFocusButtons();
        },
        setQuantityPartOfFree(partRatio) {
            if (this.activeSide.value === FuturesOrderSides.OPEN.value) {
                this.total = Number((this.totalBalance.freeEquivalent * Number(this.selectedLeverage)) * partRatio).floor(this.pricePrecision);
            } else if (this.direction === SUPPORTED_DIRECTIONS.long) {
                this.quantity = Number(this.currentAssetPairLongPosition!.quantity * partRatio).floor(this.quantityPrecision);
            } else if (this.direction === SUPPORTED_DIRECTIONS.short) {
                this.quantity = Number(this.currentAssetPairShortPosition!.quantity * partRatio).floor(this.quantityPrecision);
            }
            (this.$refs.TotalInput as any).cleanFocusButtons();
        },
        setTotalMinValue() {
            const quoteAsset = this.$store.state.Assets.assets.find((a) => a.symbol === this.quoteAssetSymbol);
            if (quoteAsset) {
                const quoteAssetRate = (this.$store.state.Assets.quotations.get(quoteAsset.symbol)?.USD ?? 0);
                if (quoteAssetRate !== 0) {
                    this.total = (25 / quoteAssetRate);
                    if (this.total === 0) {
                        this.total = 1 / 10 ** this.pricePrecision;
                    }
                    if (this.quantity === 0) {
                        this.quantity = 1 / 10 ** this.quantityPrecision;
                    }
                } else {
                    this.total = 0;
                }
            } else {
                this.total = 0;
            }
            (this.$refs.QuantityInput as any).cleanFocusButtons();
        },
        setTotalPartOfFree(partRatio) {
            if (this.activeSide.value === FuturesOrderSides.OPEN.value) {
                this.total = Number(this.totalBalanceFree * partRatio * Number(this.selectedLeverage)).floor(this.pricePrecision);
            } else if (this.direction === SUPPORTED_DIRECTIONS.long) {
                if (this.quantityType === QUANTITY_TYPES.base) {
                    this.total = Number(this.currentAssetPairLongPosition!.quantity * partRatio * this.price).floor(this.pricePrecision);
                } else if (this.quantityType === QUANTITY_TYPES.contract) {
                    this.total = Number(this.currentAssetPairLongPosition!.quantity * partRatio * this.price * this.contractPrice).floor(this.pricePrecision);
                }
            } else if (this.direction === SUPPORTED_DIRECTIONS.short) {
                if (this.quantityType === QUANTITY_TYPES.base) {
                    this.total = Number(this.currentAssetPairShortPosition!.quantity * partRatio * this.price).floor(this.pricePrecision);
                } else if (this.quantityType === QUANTITY_TYPES.contract) {
                    this.total = Number(this.currentAssetPairShortPosition!.quantity * partRatio * this.price * this.contractPrice).floor(this.pricePrecision);
                }
            }
            (this.$refs.QuantityInput as any).cleanFocusButtons();
        },
        autoSetPrice(type) {
            switch (type) {
                case 'BID':
                    this.price = this.bidPrice.ceil(this.pricePrecision);
                    break;
                case 'ASK':
                    this.price = this.askPrice.ceil(this.pricePrecision);
                    break;
                case 'MARKET':
                    this.price = this.lastMarketPrice?.ceil(this.pricePrecision);
                    break;
                default:
                    throw 'Unsupported auto set price type';
            }
        },
        autoSetStopPrice(type) {
            switch (type) {
                case 'BID':
                    this.stopPrice = this.bidPrice.ceil(this.pricePrecision);
                    break;
                case 'ASK':
                    this.stopPrice = this.askPrice.ceil(this.pricePrecision);
                    break;
                case 'MARKET':
                    this.stopPrice = this.lastMarketPrice?.ceil(this.pricePrecision);
                    break;
                default:
                    throw 'Unsupported auto set price type';
            }
        },
        setQuantity(num) {
            this.quantity = Number(num.toFixed(this.quantityPrecision));
        },
        setPrice(num) {
            this.price = Number(num.toFixed(this.pricePrecision));
        },
        setStopPrice(num) {
            this.stopPrice = num;
        },
        setTotal(num) {
            this.total = Number(num.toFixed(this.pricePrecision));
        },
        clearForm() {
            this.quantity = 0;
            this.total = 0;
            this.price = 0;
            this.stopPrice = 0;
            this.isTpSlEnabled = false;
        },
        async placeOrder() {
            setTimeout(() => {
                this.showTooltip = true;
            }, 100);

            this.$v.$touch();
            if (!this.$v.$invalid) {
                if (this.activeSide.value === FuturesOrderSides.OPEN.value) {
                    const res = await this.validateOrderCost();
                    if (!res) {
                        await this.$store.dispatch('Notificator/showErrorNotification', 'Not enough balance for order cost');
                        return;
                    }
                }
                const side = this.activeSide.value === 'OPEN' ? 'BUY' : 'SELL';
                await this.$store.dispatch(placeOrder({
                    accountId: this.$store.getters['Accounts/activeAccountID'],
                    contractSymbol: `${this.baseAssetSymbol}/${this.quoteAssetSymbol}`,
                    leverage: Number(this.selectedLeverage),
                    marketPrice: String(this.lastMarketPrice),
                    placementName: this.currentPlacement.name,
                    positionSide: this.direction,
                    price: String(this.price),
                    triggerPrice: String(this.stopPrice),
                    quantity: String(this.quantity),
                    side,
                    timeInForce: this.currentExpiries,
                    type: 'LIMIT_STOP',
                    tpTriggerPrice: this.isTpSlEnabled && this.tpSlPayload?.takeProfitTriggerPrice
                        ? String(this.tpSlPayload.takeProfitTriggerPrice)
                        : undefined,
                    tpOrderPrice: this.isTpSlEnabled && this.tpSlPayload?.takeProfitPrice
                        ? String(this.tpSlPayload.takeProfitPrice)
                        : undefined,
                    slTriggerPrice: this.isTpSlEnabled && this.tpSlPayload?.stopLossTriggerPrice
                        ? String(this.tpSlPayload.stopLossTriggerPrice)
                        : undefined,
                    slOrderPrice: this.isTpSlEnabled && this.tpSlPayload?.stopLossPrice
                        ? String(this.tpSlPayload.stopLossPrice)
                        : undefined,
                }));
                this.clearForm();
                this.$v.$reset();
                (this.$refs.TotalInput as any).cleanFocusButtons();
                (this.$refs.QuantityInput as any).cleanFocusButtons();
                (this.$refs.PriceInput as any).cleanFocusButtons();
                (this.$refs.StopPriceInput as any).cleanFocusButtons();
            }
        },
        switchDirection() {
            if (this.direction === SUPPORTED_DIRECTIONS.long) {
                this.direction = SUPPORTED_DIRECTIONS.short;
            } else {
                this.direction = SUPPORTED_DIRECTIONS.long;
            }
        },
        changeLeverage(leverage) {
            this.selectedLeverage = leverage;
            this.$emit('select-leverage', this.selectedLeverage);
        },
        updateLeverage() {
            if (this.currentAssetPairLongPosition) {
                this.selectedLeverage = Number(this.currentAssetPairLongPosition.leverage);
                return;
            }
            if (this.currentAssetPairShortPosition) {
                this.selectedLeverage = Number(this.currentAssetPairShortPosition.leverage);
                return;
            }
            this.selectedLeverage = 1;
        },
    },
    async created() {
        await this.$store.dispatch(getPositions(this.placement));
        await this.$store.dispatch(getAvailableLeverages(undefined));
        document.addEventListener('click', () => {
            this.showTooltip = false;
        });
    },
    async mounted() {
        if (this.presetedLeverage) {
            this.changeLeverage(this.presetedLeverage);
        }
        if (this.presetedQuantity) {
            this.quantity = this.presetedQuantity;
        }
        if (this.currentAssetPairLongPosition) {
            this.selectedLeverage = Number(this.currentAssetPairLongPosition.leverage);
        }
        if (this.currentAssetPairShortPosition) {
            this.selectedLeverage = Number(this.currentAssetPairShortPosition.leverage);
        }

        await this.getActiveSlOrder();
        await this.getActiveTpOrder();
    },
    watch: {
        tpSlUpdatesCounter() {
            this.getActiveTpOrder();
            this.getActiveSlOrder();
        },
        baseAssetSymbol() {
            this.clearForm();
        },
        quoteAssetSymbol() {
            this.clearForm();
        },
        placementId() {
            this.clearForm();
        },
        async activeAccountId(newVal, oldVal) {
            if (!oldVal) {
                await this.$store.dispatch(getPositions(this.placement));
            }
            this.updateLeverage();

            this.isTpSlEnabled = false;

            await this.getActiveSlOrder();
            await this.getActiveTpOrder();
        },
        currentAssetPairLongPosition(value) {
            if (value) {
                this.selectedLeverage = Number(value.leverage);
            }
        },
        currentAssetPairShortPosition(value) {
            if (value) {
                this.selectedLeverage = Number(value.leverage);
            }
        },
        availableLeverages(value) {
            if (!this.isShortAvailable && !this.isLongAvailable) {
                [this.selectedLeverage] = value;
            }
        },
        async pair() {
            this.updateLeverage();

            this.isTpSlEnabled = false;

            await this.getActiveSlOrder();
            await this.getActiveTpOrder();
        },
        async placement() {
            this.updateLeverage();

            this.isTpSlEnabled = false;

            await this.getActiveSlOrder();
            await this.getActiveTpOrder();
        },
        async direction() {
            this.isTpSlEnabled = false;

            await this.getActiveSlOrder();
            await this.getActiveTpOrder();
        },
        isTpSlEnabled(value) {
            if (!value) {
                this.tpSlPayload = null;
            }
        },
        price() {
            this.hasTpSlPriceError = false;
            this.hasTpSlQuantityError = false;
            this.$v.price.$reset();
        },
        quantity() {
            if (this.price === 0) {
                this.price = this.lastMarketPrice?.ceil(this.pricePrecision);
            }

            this.hasTpSlPriceError = false;
            this.hasTpSlQuantityError = false;
            this.$emit('set-quantity', this.quantity);
            this.$v.quantity.$reset();
        },
        total() {
            if (this.price === 0) {
                this.price = this.lastMarketPrice?.ceil(this.pricePrecision);
            }
            this.$v.total.$reset();
        },
        stopPrice() {
            this.$v.stopPrice.$reset();
        },
        activeTpOrder(value) {
            if (value) {
                this.isTpSlEnabled = true;
            } else if (!this.tpSlPayload) {
                this.isTpSlEnabled = false;
            }
        },
        activeSlOrder(value) {
            if (value) {
                this.isTpSlEnabled = true;
            } else if (!this.tpSlPayload) {
                this.isTpSlEnabled = false;
            }
        },
    },
});
