
import Vue from 'vue';
import { mapGetters } from 'vuex';

import 'vue-range-component/dist/vue-range-slider.css';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import { changeLeverage } from 'Store/v2/Futures';
import { HIDE_SHADOW, SHOW_SHADOW } from 'Store/v2/Preloader';

interface Data {
    showPopup: boolean;
    activeLeverage: null | number;
    isMoving: boolean;
    RANGE_WIDTH: number;
    inputLeverageTimeoutId: null | number;
    selectedLeverageIndexTimeoutId: null | number;
    delay: number;
}

interface Methods {
    cancelLeverage: () => void;
    confirmLeverage: () => void;
    sliderLeftOffset: () => string;
    leverageMargin: (data: number) => string;
    dotPosition: (data: number) => string;
    switchPopup: () => void;
    onMouseDown: () => void;
    onMouseUp: () => void;
    onMouseMove: (data: any) => void;
    onClick: (data: any) => void;
    selectLeverage: (data: number, index: number) => void;
    onInputLeverage: (data: any) => void;
    increaseLeverage: () => void;
    decreaseLeverage: () => void;
    checkIsLeverageValid: () => void;
}

interface Computed {
    blockWidth: number;
    blocksEdgesArray: number[];
    activeLineWidth: string;
    reducedLeverages: number[];
    activeValueIndex: number;
    availableMargin: string;
    availableValue: string;
    activeAccountId: string;
}

export default Vue.extend<Data, Methods, Computed, any>({
    props: {
        leverages: {
            type: Array,
            required: true,
        },
        selectedLeverage: {
            type: Number,
            required: true,
        },
        isAnyPositionOpened: {
            type: Boolean,
            default: false,
        },
        isKycVerified: {
            type: Boolean,
            default: false,
        },
        openedPosition: {
            type: undefined,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Icon,
        Button,
    },
    data() {
        return {
            showPopup: false,
            activeLeverage: null,
            isMoving: false,
            RANGE_WIDTH: 384,
            inputLeverageTimeoutId: null,
            selectedLeverageIndexTimeoutId: null,
            delay: 500,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
        }),
        availableMargin() {
            return this.$store.state.Balances.balances.find((b) => {
                return b.placementName === this.openedPosition?.placementName
                    && b.assetSymbol === this.openedPosition?.contractSymbol.split('/')[1]
                    && b.accountId === this.activeAccountId;
            })
                ?.free
                .toFixed(4)
                .noExponents()
                .getSeparatedDigits();
        },
        availableValue() {
            return ((this.$store.state.Balances.balances.find((b) => {
                return b.placementName === this.openedPosition?.placementName
                    && b.assetSymbol === this.openedPosition?.contractSymbol.split('/')[1]
                    && b.accountId === this.activeAccountId;
            })
                ?.free ?? 0) * (this.openedPosition?.leverage ?? 1))
                .toFixed(4)
                .noExponents()
                .getSeparatedDigits();
        },
        blockWidth() {
            if (this.leverages.length === 0) {
                return 0;
            }
            return this.RANGE_WIDTH / this.leverages.length;
        },
        blocksEdgesArray() {
            const result: number[] = [];
            let accumulator = 0;
            for (let i = 0; i < this.leverages.length; i += 1) {
                result.push(accumulator + this.blockWidth);
                accumulator += this.blockWidth;
            }
            return result;
        },
        activeLineWidth() {
            return `${(this.activeValueIndex / (this.leverages.length - 1)) * 100}%`;
        },
        reducedLeverages() {
            if (this.leverages.length < 13) {
                return this.leverages;
            }
            let result = this.leverages;
            // eslint-disable-next-line no-constant-condition
            while (true) {
                // eslint-disable-next-line no-loop-func
                const temp = result.filter((_, index) => index % 2 === 0 || index === result.length - 1);
                result = temp;
                if (result.length < 13) {
                    break;
                }
            }
            return result;
        },
        activeValueIndex: {
            get() {
                return this.leverages.findIndex((l) => l === this.activeLeverage);
            },
            set(value) {
                this.activeLeverage = value;
            },
        },
    },
    methods: {
        // close popup and discard changes
        cancelLeverage() {
            this.activeLeverage = this.selectedLeverage;
            this.activeValueIndex = this.selectedLeverage;
            this.showPopup = false;
        },
        // close popup and accept changes
        async confirmLeverage() {
            try {
                if (this.isAnyPositionOpened) {
                    await this.$store.dispatch(changeLeverage(Number(this.activeLeverage)));
                }
                this.$emit('leverage', this.activeLeverage);
                this.showPopup = false;
            } catch {
                // code crushed because of network error
            }
        },
        // margin of slider value (different margin value for different lengths of value)
        sliderLeftOffset() {
            if (this.activeValueIndex === 0) {
                return '4px';
            }
            if (String(this.activeLeverage).length === 2) {
                return '-5px';
            }
            if (String(this.activeLeverage).length === 3) {
                return '-12px';
            }
            return '0px';
        },
        // margin of ruler scale values (different margin value for different lengths of scale value)
        leverageMargin(leverage) {
            const strLeverage = String(leverage);
            if (strLeverage.length === 1) {
                return '-5px';
            }
            if (strLeverage.length === 2) {
                return '-7px';
            }
            if (strLeverage.length === 3) {
                return '-10px';
            }
            return '-5px';
        },
        // positions of dots on range input (custom for first and last dots)
        dotPosition(index) {
            if (index === 0) {
                return '12px';
            }
            if (index === this.reducedLeverages.length - 1) {
                return '372px';
            }
            return `${(index / (this.reducedLeverages.length - 1)) * 100}%`;
        },
        // switch show/hide popup
        switchPopup() {
            if (this.disabled) {
                return;
            }
            this.showPopup = !this.showPopup;
        },
        // define flag of sliding of range input to true
        onMouseDown() {
            this.isMoving = true;
        },
        // define flag of sliding of range input to false
        onMouseUp() {
            this.isMoving = false;
        },
        // change width of an active area of range input while isMoving property is set to true
        onMouseMove(event) {
            if (!this.isMoving || (event.target.id !== 'RangeContainer' && event.target.id !== 'RangeContainerActive')) {
                return;
            }
            this.blocksEdgesArray.forEach((el, index, array) => {
                if (index === 0) {
                    if (event.offsetX < array[0]) {
                        [this.activeValueIndex] = this.leverages;
                    } else if (event.offsetX < array[1] && event.offsetX > array[0]) {
                        [, this.activeValueIndex] = this.leverages;
                    }
                } else if (index === array.length - 1) {
                    if (event.offsetX > array[array.length - 2]) {
                        this.activeValueIndex = this.leverages[array.length - 1];
                    }
                } else if ((event.offsetX > el && event.offsetX < array[index + 1]) || event.offsetX === el) {
                    this.activeValueIndex = this.leverages[index + 1];
                }
            });
        },
        // change width of an active area of range input when user clicks on range input area
        onClick(event) {
            if (event.target.id !== 'RangeContainer' && event.target.id !== 'RangeContainerActive') {
                return;
            }
            this.blocksEdgesArray.forEach((el, index, array) => {
                if (index === 0) {
                    if (event.offsetX < array[0]) {
                        [this.activeValueIndex] = this.leverages;
                    } else if (event.offsetX < array[1] && event.offsetX > array[0]) {
                        [, this.activeValueIndex] = this.leverages;
                    }
                } else if (index === array.length - 1) {
                    if (event.offsetX > array[array.length - 2]) {
                        this.activeValueIndex = this.leverages[array.length - 1];
                    }
                } else if ((event.offsetX > el && event.offsetX < array[index + 1]) || event.offsetX === el) {
                    this.activeValueIndex = this.leverages[index + 1];
                }
            });
        },
        // change active leverage value
        selectLeverage(leverage) {
            this.activeLeverage = leverage;
        },
        onInputLeverage(e) {
            if (this.inputLeverageTimeoutId) {
                clearTimeout(this.inputLeverageTimeoutId);
            }
            this.activeLeverage = Number(e.target.value);
            this.inputLeverageTimeoutId = setTimeout(() => {
                this.checkIsLeverageValid();
                this.inputLeverageTimeoutId = null;
            }, this.delay) as any;
        },
        checkIsLeverageValid() {
            if (this.leverages.indexOf(this.activeLeverage) === -1) {
                this.activeLeverage = 1;
            }
        },
        increaseLeverage() {
            const index = this.leverages.findIndex((l) => l === this.activeLeverage);
            if (index !== this.leverages.length - 1 && index !== -1) {
                this.activeLeverage = this.leverages[index + 1];
            }
        },
        decreaseLeverage() {
            const index = this.leverages.findIndex((l) => l === this.activeLeverage);
            if (index !== 0 && index !== -1) {
                this.activeLeverage = this.leverages[index - 1];
            }
        },
    },
    mounted() {
        [this.activeLeverage] = this.leverages;

        // close popup on click outside
        document.addEventListener('click', (event) => {
            const { path } = (event as any);
            let flag = false;
            if (!path) {
                return;
            }
            Array.from(path).forEach((el) => {
                if ((el as any).classList) {
                    if (Array.from((el as any).classList).includes('leverage-popup')) {
                        flag = true;
                    }
                }
            });
            if (!flag) {
                this.activeLeverage = this.selectedLeverage;
                this.showPopup = false;
            }
        });
    },
    beforeDestroy() {
        this.$store.commit(HIDE_SHADOW(undefined));
    },
    watch: {
        leverages(value) {
            this.activeLeverage = value.find((l) => l === this.selectedLeverage);
        },
        selectedLeverage(value) {
            this.activeLeverage = value;
        },
        activeValueIndex(value) {
            if (this.selectedLeverageIndexTimeoutId) {
                clearTimeout(this.selectedLeverageIndexTimeoutId);
            }
            this.selectedLeverageIndexTimeoutId = setTimeout(() => {
                this.activeLeverage = this.leverages[value];
                this.selectedLeverageIndexTimeoutId = null;
            }, this.delay) as any;
        },
        showPopup(value) {
            if (value) {
                this.$store.commit(SHOW_SHADOW(undefined));
            } else {
                this.$store.commit(HIDE_SHADOW(undefined));
            }
        },
    },
});
