
import Vue from 'vue';
import { mapGetters } from 'vuex';

import numberFormater from 'Mixins/numberFormater';
import { ADD_ASSET_PAIR } from 'Store/v2/TradingData';
import ApiError from 'Entities/ApiError';
import SettingsApi from 'Apis/Settings';
import FavoriteAssetPair from 'Entities/userSettings/FavoriteAssetPair';
import { stringToFixedDigits } from 'Lib/utils/stringToFixedDigits';
import { ADD_SPOT_ASSET_PAIR, getFavoriteAssetPairsList, setActiveTerminalAssetPairId } from 'Store/v2/AssetPairs';
import theme from 'Theme';
import AssetValue from 'UI/AssetValue.vue';
import Shift from 'UI/Shift.vue';
import Icon from 'UI/Icon.vue';

export default Vue.extend<any, any, any, any>({
    name: 'PairRow',
    mixins: [numberFormater],
    components: {
        AssetValue,
        Shift,
        Icon,
    },
    props: {
        pair: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            stringToFixedDigits,
            theme,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        ticker() {
            return this.pair.ticker ? this.pair.ticker : {};
        },
        active() {
            return this.$store.getters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_ID'];
        },
        isActive() {
            return this.active === this.pair.id;
        },
        isFavorite() {
            return !!this.$store.state.AssetPairs.favoriteTools.find((t) => t.symbol === this.pair.symbol && t.placementName === this.$store.getters['Placements/activeTerminalPlacementName']);
        },
        priceLayout() {
            if (!this.ticker.price) {
                return '0';
            }

            return Number(this.ticker.price.floor(8)) === 0 ? this.ticker.price.noExponents() : this.ticker.price.floor(8).noExponents().getSeparatedDigits();
        },
        percentChangeLayout() {
            if (!this.ticker.percentChange) {
                return '0';
            }
            return Number(this.ticker.percentChange.toFixed(2));
        },
        hasLeverage() {
            return (
                this.pair.placementLeverages !== null
                && this.pair.placementLeverages !== undefined
                && this.pair.placementLeverages.length > 0
            );
        },
        maxLeverage() {
            if (!this.hasLeverage) {
                return undefined;
            }
            return Math.max(...this.pair.placementLeverages.map(({ level }) => Number(level)));
        },
    },
    methods: {
        async setActiveTerminalAssetPairId() {
            if (!this.isActive) {
                this.$store.commit(ADD_SPOT_ASSET_PAIR(this.pair));
                this.$store.commit(ADD_ASSET_PAIR(this.pair));
                await this.$store.dispatch(setActiveTerminalAssetPairId(this.pair.id));
                this.$emit('choose', this.pair.id);
            }
        },
        async toggleFavorite() {
            if (this.isFavorite) {
                await this.removeFromFavorite();
                this.$emit('remove-favorite');
            } else {
                await this.addToFavorite();
            }
        },
        async addToFavorite() {
            try {
                await SettingsApi.addFavorite(new FavoriteAssetPair({
                    placementName: this.$store.getters['Placements/activeTerminalPlacementName'],
                    symbol: this.pair.symbol,
                }));
                await this.$store.dispatch(getFavoriteAssetPairsList(undefined));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during adding tool to favorites');
                }
            }
        },
        async removeFromFavorite() {
            try {
                await SettingsApi.deleteFavorite(new FavoriteAssetPair({
                    placementName: this.$store.getters['Placements/activeTerminalPlacementName'],
                    symbol: this.pair.symbol,
                }));
                await this.$store.dispatch(getFavoriteAssetPairsList(undefined));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during removing tool from favorites');
                }
            }
        },
    },
});
